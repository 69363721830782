import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./signin.css";
import logo from "../../Images/Logo.png";
import loginImage from "../../Images/loginImage.png";
import GoogleSignInButton from "../../components/GoogleSignInButton";
import errorEmailLogo from "../../Images/emailerror.png";
const Signin = () => {
  const [signInDetails, setSignInDetails] = useState({
    email: "",
    password: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  

  // This effect checks if the email and password fields are filled, including autofill
  useEffect(() => {
    const allFieldsFilled =
      signInDetails.email.trim() !== "" && signInDetails.password.trim() !== "";

    setIsTermsAccepted(allFieldsFilled);
  }, [signInDetails.email, signInDetails.password]); // Run when these values change

  // const passwordRegex = {
  //   minLength: /(?=.{6,})/,
  //   hasUppercase: /(?=.*[A-Z])/,
  //   hasNumber: /(?=.*\d)/,
  // };

  // const [passwordValidation, setPasswordValidation] = useState({
  //   minLength: false,
  //   hasUppercase: false,
  //   hasNumber: false,
  // });
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const router = useRoutes();
  // console.log(router, "router in login page");
  // const [passwordValidation, setPasswordValidation] = useState({
  //   minLength: false,
  //   hasUppercase: false,
  //   hasNumber: false,
  // });

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSignupDetailsChange = (event) => {
    const { name, value } = event.target;

    setSignInDetails((prevSignInDetails) => ({
      ...prevSignInDetails,
      [name]: value,
    }));

    if (name === "email" && emailError) {
      setEmailError("");
    }
    if (name === "password" && passwordError) {
      setPasswordError("");
    }
    // Check if both fields are filled
    const allFieldsFilled =
      signInDetails.email !== "" && signInDetails.password !== "";
    setIsTermsAccepted(allFieldsFilled);
    // setIsTermsAccepted(false)
    setIsBtnDisabled(false);
  };

  const renderPasswordAsterisks = (password) => {
    return "*".repeat(password.length); // Repeat * for each character in the password
  };

  const navigate = useNavigate();

  const handleSignin = async () => {
    setIsBtnDisabled(true);
    const payload = {
      email: signInDetails.email,
      password: signInDetails.password,
    };
    // console.log(payload);
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/signin/",
        payload
      );
      console.log("response login page", response);

      if (response.data.message === "Sign-in successful.") {
        // setFirstName(response.data.first_name);
        // setLastName(response.data.last_name)
        // console.log("firstname", response.data.first_name);
        // console.log("lastname", response.data.last_name);

        localStorage.setItem("token", response.data.token);

        // If Remember Me is checked, store the email in localStorage
        localStorage.setItem("email", signInDetails.email);
        localStorage.setItem("firstName", response.data.first_name);
        localStorage.setItem("lastname", response.data.last_name);
        //  localStorage.setItem("first_name", firstName);
        //  localStorage.setItem("last_name", lastName);
        //  if (rememberMe) {
        //   localStorage.setItem("email", signInDetails.email);
        // } else {
        //   localStorage.removeItem("email"); // Remove email if Remember Me is unchecked
        // }

        toast.success("Sign-in successful", {
          autoClose: 2000,
        });

        navigate("/uploadFile");
      } else if (response.data.message === "Invalid email.") {
        console.log("invalid email or password 1111111111");
        setEmailError("Incorrect Email, Try again");
        setPasswordError(""); // Clear any existing password error
      } else if (
        response.data.message === "Invalid password for the provided email."
      ) {
        console.log("password error in sign in");
        setPasswordError("Incorrect Password, Try again");
        setEmailError(""); // Clear any existing email error
      }
    } catch (error) {
      // Handle signup error
      toast.error("Signup failed. Please try again.");
      // console.error("Signup error:", error);
    } finally {
      setLoading(false); // Re-enable button after API call completes
    }
  };
  // verify signin button
  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

  
  // const handleNavigateToForgotPassword = () => {
  //   navigate('/forgot-password');
  // };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setVerified(true);
      navigate("/uploadFile"); //navigate("/ai-copilot");
    }
    setIsBtnDisabled(false);
  }, []);

  useEffect(() => {
    // If "Remember me" was previously selected, pre-fill the email field
    const rememberedEmail = localStorage.getItem("email");
    if (rememberedEmail) {
      setSignInDetails((prevSignInDetails) => ({
        ...prevSignInDetails,
        email: rememberedEmail,
      }));
      setRememberMe(true); // Set the checkbox to true
    }
  }, []);
  // If verified is true, navigate to "/uploadFile"
  // useEffect(() => {
  //   if (verified) {
  //     navigate("/uploadFile");
  //   }
  // }, [verified, navigate]);
  return (
    <div className=" sigin-container bg-[#FEF9F7]" >
<span className="main">
  <div className="logo-container">
    <img
      src="/docusensasign.svg"
      alt="docuSensa logo"
      className="w-full h-full object-contain"
    />
  </div>
</span>


      <section className="flex justify-center items-center">
        <div className="w-[411px] h-[480px] border border-[rgba(0,0,0,0.10)] bg-white rounded-[12px] flex flex-col p-6 mt-[115px] ml-[-40px]">
          <div>
            <h2 className="welcome-signin">Welcome Back</h2>
            <h3 className="credentials-signin">
              Enter your Credentials to Access your Account
            </h3>
          </div>
          <div>
            <div className="google-signin-button">
            <GoogleSignInButton 
        setVerified={setVerified}
        loginMessage={loginMessage}
      />
              {/* <img
                src="/googleicon.svg"
                alt="Google icon"
                className="google-icon"
              />
              <span className="google-text">Continue with Google</span> */}
            </div>

            {/* <GoogleSignInButton 
        setVerified={setVerified}
        loginMessage={loginMessage} 
      /> */}
          </div>

          <div className="or-style my-6">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-gray-500">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>

          <div>
            <label for="email" className="email-sigin">
              Email
            </label>
            <div className="Email-sigin-box relative flex items-center">
              <img
                src="/email-signin.svg"
                alt="Email icon"
                className="emailsigin-icon w-5 h-5 absolute left-3" // Fixed size for the image
              />
              <div className="line-between-icon absolute left-12 top-1/2 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

              <input
                type="email"
                name="email"
                value={signInDetails.email}
                onChange={handleSignupDetailsChange}
                className="Email-sigin-placeholder border-none outline-none" // Padding added to make room for the icon
                placeholder={emailError || "Enter your Email"}
                required
              />
{emailError && (
  <div
    className="relative flex items-center"
    onMouseEnter={() => setIsHovered(true)}  // On hover, set state to true
    onMouseLeave={() => setIsHovered(false)} // On mouse leave, set state to false
  >
    <span className="w-[20px] h-[20px] mr-1 cursor-pointer">
      <img src="/error.svg" alt="email error logo" />
    </span>

    {/* Tooltip will only show if hovered */}
    {isHovered && (
    <div className="popup-show-letter email-error-hover">
        <p>{emailError}</p>
    </div>
)}

  </div>
)}


            </div>
          </div>

          <div>
            <label htmlFor="password" className="password-sigin">
              Password
            </label>
            <div className="password-sigin-box flex items-center border ">
              <img
                src="/passkey.svg"
                alt="Password icon"
                className="emailsigin-icon w-5 h-5 ml-3"
              />
              <div className="line-between-icon  ml-3.5 mt-8 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={signInDetails.password}
                onChange={handleSignupDetailsChange}
                placeholder={passwordError || "Enter Your Password"}                className=" password-sigin-placeholder border-none outline-none ml-1.5"
                required
              />
                 {passwordError && (
  <div
    className="relative flex items-center"
    onMouseEnter={() => setIsHovered(true)}  // On hover, set state to true
    onMouseLeave={() => setIsHovered(false)} // On mouse leave, set state to false
  >
    <span className="w-[20px] h-[20px] mr-3 cursor-pointer">
      <img src="/error.svg" alt="email error logo" />
    </span>

    {/* Tooltip will only show if hovered */}
    {isHovered && (
      <div className="popup-show-letter email-error-hover">
        <p>{passwordError}</p>
      </div>
    )}
  </div>
)}

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="text-gray-500 ml-8"
              >
                {showPassword ? (
                  <IoEyeOutline size={22} />
                ) : (
                  <IoEyeOffOutline size={22} />
                )}
              </button>
            </div>
        

            {/* {passwordError && (
              <p className="text-red-500 text-sm mt-0 flex items-center">
                <span className="w-[10px] h-[10px] mr-1">
                  <img src={errorEmailLogo} alt="password error logo" />
                </span>{" "}
                {passwordError}
              </p>
            )} */}
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center remember-me-checkbox">
              <input type="checkbox" className="w-[20px] h-[20px] mt-3.5" />
              <label className=" remember-sigin ml-2  text-[rgba(115,96,123,0.60)] ">
                Remember me
              </label>
            </div>
            <a
              href="#"
              className="text-[#BB42AE] mt-3 font-inter text-[13px] font-medium"
            >
              {" "}
              <Link
                to="/request-password-reset"
                className="forgot-pass-sigin mr-2 mt-4"
              >
                Forgot password
              </Link>
            </a>
          </div>

          <div className="w-[355px] h-[62px] bg-[#8B55DD] rounded-[6px] flex justify-center items-center " >
            <button
              disabled={isBtnDisabled}
              onClick={handleSignin}
              type="submit"
              className={`signin-button ${
                isTermsAccepted ? "isTermAccepted" : "notAccepted"
              } ${isBtnDisabled ? "notAccepted" : "isTermAccepted"}`}
            >
              Login
            </button>
          </div>
        </div>
      </section>

      <p className="login-text-sigin mt-2">
        Don’t have an account?{" "}
        <Link to="/signup" className="login-click-singin">
          Signup Now
        </Link>
      </p>
    </div>
  );
};

export default Signin;
