import React, { useState, useRef, useEffect } from "react";
import "./filterModal.css";
import axios from "axios";

const FilterModal = ({ isOpen, onClose, token, setContracts }) => {
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  // State variables
  const [filterType, setFilterType] = useState("");
  const [disableQuickSelect, setDisableQuickSelect] = useState(false);
  const [disableDateRange, setDisableDateRange] = useState(false);
  const [timeRange, setTimeRange] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // Reset filters when the modal is opened
  useEffect(() => {
    if (isOpen) {
      resetFilters();
    }
  }, [isOpen]);

  // Reset filters function
  const resetFilters = () => {
    setFilterType("");
    setDisableQuickSelect(false);
    setDisableDateRange(false);
    setTimeRange("");
    setFromDate("");
    setToDate("");
    if (fromDateRef.current) fromDateRef.current.value = ""; // Clear input value for From Date
    if (toDateRef.current) toDateRef.current.value = ""; // Clear input value for To Date
  };

  const openDatePicker = (ref) => {
    if (ref.current) {
      if (ref.current.showPicker) {
        ref.current.showPicker();
      } else {
        console.log("Your browser does not support the showPicker API.");
      }
    }
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleTimeRangeChange = (e) => {
    setTimeRange(e.target.value);
    setDisableDateRange(true); // Disable From-To date when Quick Select is used
    setDisableQuickSelect(false); // Ensure Quick Select is active
    setFromDate(""); // Clear dates
    setToDate(""); // Clear dates
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setDisableQuickSelect(true); // Disable Quick Select when From-To date is used
    setDisableDateRange(false); // Ensure From-To date is active
    setTimeRange(""); // Clear time range
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setDisableQuickSelect(true); // Disable Quick Select when From-To date is used
    setDisableDateRange(false); // Ensure From-To date is active
    setTimeRange(""); // Clear time range
  };

  const applyFilter = async () => {
    if (!filterType) {
      console.log("Validation Error: Please select a filter type.");
      return;
    }

    if (!timeRange && (!fromDate || !toDate)) {
      console.log(
        "Validation Error: Please provide a valid Quick Select option or From-To dates."
      );
      return;
    }

    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      console.log("Validation Error: From date cannot be later than To date.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/filter-contracts/`,
        {
          params: {
            filter_type: filterType,
            time_range: disableDateRange ? timeRange : undefined,
            from_date: !disableDateRange ? fromDate : undefined,
            to_date: !disableDateRange ? toDate : undefined,
          },
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const data = response.data.contracts;

      if (data && data.length > 0) {
        console.log("Filter Applied Successfully. Data:", data);
        setContracts(data); // Send data to parent component
      } else {
        console.log(
          "No data found for the selected filter. Displaying an empty table."
        );
        setContracts([]); // Set an empty array to indicate no data
      }

      onClose(); // Close modal
    } catch (error) {
      console.error("Error applying filter:", error);
      console.log("Error: Failed to apply filter. Please try again.");
      setContracts([]); // Handle API failure by showing an empty table
      onClose(); // Close modal
    }
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal">
        <button className="close-button-filter" onClick={onClose}>
          ×
        </button>
        <h2 className="filter-title">Filter By</h2>

        <div className="custom-select-wrapper">
          <select
            className="filter-select"
            value={filterType}
            onChange={handleFilterTypeChange}
          >
            <option value="" disabled hidden>
              Select a filter
            </option>
            <option value="renewal_date">Renewal Date</option>
            <option value="expiration_date">Expiration Date</option>
          </select>
          <img src="/down.svg" alt="dropdown icon" />
        </div>

        <h3 className="quick-select-label">Quick select</h3>
        <div className="custom-select-wrapper1">
          <select
            className={`quick-select ${disableQuickSelect ? "disabled" : ""}`}
            value={timeRange}
            onChange={handleTimeRangeChange}
            disabled={disableQuickSelect} // Disable Quick Select if From-To dates are used
          >
            <option value="" disabled hidden>
              Select here
            </option>
            <option value="this_month">This month</option>
            <option value="next_three_months">Next 3 Months</option>
            <option value="this_year">This year</option>
          </select>
          <img src="/down.svg" alt="dropdown icon " className="icon-filter-drop" />
        </div>

        <div className="date-picker-container">
          <div className="date-picker">
            <label>From</label>
            <div
              className={`date-input-wrapper ${
                disableDateRange ? "disabled" : ""
              }`}
              onClick={() => openDatePicker(fromDateRef)}
            >
              <img src="/calendar.svg" alt="calendar" className="calendar-icon-filter" />
              <input
                type="text"
                ref={fromDateRef}
                className="date-input"
                placeholder="--:--:----"
                value={fromDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={handleFromDateChange}
                disabled={disableDateRange} // Disable From date if Quick Select is used
              />
            </div>
          </div>
          <div className="date-picker">
            <label>To</label>
            <div
              className={`date-input-wrapper ${
                disableDateRange ? "disabled" : ""
              }`}
              onClick={() => openDatePicker(toDateRef)}
            >
              <img src="/calendar.svg" alt="calendar" className="calendar-icon-filter" />
              <input
                type="text"
                ref={toDateRef}
                className="date-input"
                placeholder="--:--:----"
                value={toDate}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={handleToDateChange}
                disabled={disableDateRange} // Disable To date if Quick Select is used
              />
            </div>
          </div>
        </div>

        <button className="apply-filter-button-filter" onClick={applyFilter}>
          Apply Filter
        </button>
      </div>
    </div>
  );
};

export default FilterModal;
