import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate , useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './otp.css'
import close from '../../../Images/close.jpg'
import verifylogo from "../../../Images/Succes-email.png"
const OtpPage = ({ closeModal, setEmailAccountVerified }) => {

  const location = useLocation(); // Get state from the navigation
  
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState('');
  const [verify, setVerify] = useState(false)
  const [timeLeft, setTimeLeft] = useState(30); // Timer for Resend OTP
  const [isResendDisabled, setIsResendDisabled] = useState(true); 

  const inputs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/signup"); // Navigate to the signup page
  };



  useEffect(() => {
    // Retrieve email from location state
    const emailFromState = location.state?.email;
    console.log("Email from state:", emailFromState);
    
    if (emailFromState) {
      setEmail(emailFromState);
    } else {
      navigate("/signup"); // Redirect to signup if email is not available
    }
  
    // Set focus on the first input box when the component mounts
    if (inputs[0].current) {
      inputs[0].current.focus();
    }
  
    // Start the timer only when the component is first loaded or when the timeLeft is reset
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer); 
          setIsResendDisabled(false); 
          return 0;
        }
        return prevTime - 1; // Decrease time by 1 second
      });
    }, 1000);
  
    
    return () => clearInterval(timer);
  
  }, [location.state, navigate, timeLeft]); 
  
  const token = localStorage.getItem('token');
  console.log("shdshdsjd",token);
 

  const handleInput = (e, index) => {
    const input = e.target;
    if (index < inputs.length - 1 && input.value !== "") {
      inputs[index + 1].current.focus();
    }
    const newOtp = otp.slice(0, index) + input.value + otp.slice(index + 1);
    setOtp(newOtp);
  };
  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text"); // Get the pasted value
    if (pastedData.length === inputs.length) {
      // Ensure the pasted value length matches the number of input boxes
      pastedData.split("").forEach((char, idx) => {
        if (inputs[idx]) {
          inputs[idx].current.value = char; // Set value in the corresponding input box
        }
      });
      setOtp(pastedData); // Update OTP state with the full pasted value
      e.preventDefault(); // Prevent default paste behavior
    }
  };
  
  // const handleInput = (e, index) => {
  //   const input = e.target.value;

  //   // Allow only one character input and ensure it's numeric
  //   if (!/^\d*$/.test(input) || input.length > 1) return;

  //   const newOtpArray = [...otp];
  //   newOtpArray[index] = input; 
  //   const newOtp = newOtpArray.join(""); 
  //   setOtp(newOtp);

  //   // Move to the next input if filled
  //   if (input && index < inputs.length - 1) {
  //     inputs[index + 1].current.focus();
  //   } 
  // };
  

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      inputs[index - 1].current.focus();
    }
  };
  const handleNavigation = () => {
    navigate("/uploadFile"); // Navigate to the uploadFile page
  };

  useEffect(() => {
    if (verify) {
      navigate("/uploadFile"); // Navigate to the uploadFile page
    }
  }, [verify, navigate]); // Ensure to include navigate in the dependency array
  
  const handleVerify = async () => {
    // e.preventDefault();

    console.log("Email:", email);
    console.log("OTP:", otp);

    const data = {
      email: email,
      otp: otp,
    };

    try {
     const response =  await axios.post(
      process.env.REACT_APP_API_URL + "/api/v1/accounts/verify/", 
        data, 
        {
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
    console.log("otp verification", response);
    if(response.data.message === "Account verified"){
      toast.success("Account verified", {
        autoClose: 1000,
      });
      setVerify(true)
      setEmailAccountVerified(true)
    }
    else if (response.data.data === "Wrong OTP") {
      toast.warn("Wrong OTP", {
        autoClose: 2000,
      });
    } else if (response.data.data === "Invalid email") {
      toast.warn("Invalid email", {
        autoClose: 2000,
      });
    }
    } catch (error) {
      console.error("Error while verifying OTP:", error);
    }
  };

  const restartTimer = () => {
    setTimeLeft(30); // Reset timer to 30 seconds
    setIsResendDisabled(true); // Disable the resend button
  };

  console.log("token",token);
  
  const handleResendOTP = async () => {
    if (isResendDisabled) return; // Prevent action if button is disabled
    
    try {
      const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
        "Content-Type": "application/json",
      };
  
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/resend-otp/",
        null,
        { headers }
      );
  
      if (response.data.message === "New OTP sent to your email address.") {
        toast.success("OTP resent successfully", { autoClose: 1000 });
  
        // Reset OTP input
        setOtp("");
        inputs.forEach((input) => (input.current.value = ""));
  
        // Restart the timer and disable the resend button
        restartTimer();
      } else if (response.data.message === "Your account is already verified.") {
        toast.info("Your account is already verified", { autoClose: 1000 });
      }
    } catch (error) {
      console.error("Error while resending OTP:", error);
    }
};

  
  
  
  return (
<div className="otp-fullContainer relative">
  {/* Container with relative positioning */}
  <div className="w-[164.713px] h-[30px] flex-shrink-0 absolute top-[61px] left-[661px] bg-#FFF">
    <img
      src="/docusensasign.svg"
      alt="docuSensa logo"
      className="w-full h-full"
    />
  </div>
    <div className="absolute main-otppage z-50">
      <div className=" flex items-center justify-center ">

    {verify ? (
      <div className="otpverify-container relative bg-white h-auto text-center">
        <div>
          <div className="flex justify-center items-center mt-8 ">
            <img src={verifylogo} alt="email verify logo" className="w-[245px] h-[98px]"/>
          </div>
          <div className="mt-3">
              <h2 className="verify-topHeading">Your E-mail has been Verified</h2>
              <p className="verify-subHeading">Your email has been successfully verified.</p>
          </div>
          <div>
          <button
                    onClick={handleNavigation} // Navigate to /uploadFile when Close is clicked
                    className="close-verifybtn text-white text-center mt-9"
                  >
            Close
          </button>
          </div>
        </div>
      </div>
    ) : (
      <div className="otp-container relative  h-auto text-center">
          <div className="text-left">
            <img src="/otp-pageIcon.svg" alt="" className="otp-PageIcon"/>
            <h1 className="top-heading">Verify Email </h1>
            <div className="sub-heading w-[415px] mt-4">
  <p>
    Please enter the 6 digit Verification code that has been sent to { }
    {email.replace(/(^.{2})(.*)(@.*)/, "$1******$3")}
  </p>
</div>
          </div>
          {/* <button className="absolute top-5 right-9" onClick={closeModal}>
            <img src={close} alt="close button"/>
          </button> */}
          

          <div className="otp-box-cont flex flex-row gap-3 items-center">
            {inputs.map((inputRef, index) => (
              <input
                key={index}
                className="otp-box"
                type="text"
                ref={inputRef}
                maxLength="1"
                value={otp[index] || ""}
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                onPaste={(e) => handlePaste(e)} // Add onPaste handler

              />
            ))}
          </div>

          <div>
  {isResendDisabled ? (
    <button disabled className="resend-btn flex justify-end mt-[15px] resent-btn">
      Resend OTP in {timeLeft.toString().padStart(2, '0')}:{(timeLeft % 60).toString().padStart(2, '0')}
    </button>
  ) : (
    <button onClick={handleResendOTP} className="otp-resend">
      <span className="otp-text">Did not receive OTP?</span>
      <span className="otp-resend-text"> Resend OTP</span>
    </button>
  )}
</div>




          <div className="flex justify-start ml-8 mt-4">
            <button
              onClick={handleVerify}
              className="verify-btn text-center"
            >
              Verify E-mail
            </button>
          </div>

         
          
        </div>
        
    )}

        
      </div>
      <p className="wrong-email">Wrong Email id? <button onClick={handleGoBack} className="go-back-button">
  <span>Go back</span>
</button></p>
    </div>
    </div>
  );
};

export default OtpPage;