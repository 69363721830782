import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Header } from "../../components/Header/Header";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import axios from "axios";
import "./UploadFilePage.css";
import { toast } from "react-toastify";
import StatusModal from "./StatusModal";
import UploadModal from "../Contract/UploadModal";
import CreateFolderModal from "../Contract/CreateFolderModal";
import FilterModal from "./FilterModal";
import chatSlice from "../../Features/chatSlice.js";
import "../Contract/contract_data.css";

const UploadFilePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  // Stores the document being renamed
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    folder: true,
    normalDocument: true,
    contract: false,
    invoices: false,
  });
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(null);
  const actionMenuRef = useRef(null);
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false); // Controls the popup visibility
  const [successFolderName, setSuccessFolderName] = useState(""); // Stores the folder name

  const [file_ids, setFileId] = useState(null); // State to store folder ID

  const [isRenameFolderModalOpen, setIsRenameFolderModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);

  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [editContractId, setEditContractId] = useState(null); // Track which contract is in edit mode
  const [editedContractData, setEditedContractData] = useState({}); // Store edited row data
  const [currentPage, setCurrentPage] = useState(1); // Start on the first page
  const [rowsPerPage, setRowsPerPage] = useState(7); // Start on the first page
  // Replace with dynamic data if needed
  const editRowRef = useRef(null);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const pdf_key = localStorage.getItem("pdfKey");
  const tbodyRef = useRef(null);
  const [exportInProgress, setExportInProgress] = useState(false); // Tracks export status
  const [deleteInProgress, setDeleteInProgress] = useState(false); // Tracks delete status

  const [pdfToView, setPdfToView] = useState(null); // Tracks the PDF URL to display
  const [isViewerOpen, setIsViewerOpen] = useState(false); // Tracks if the viewer is open

  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null); // The document to copy
  const [selectedFolder, setSelectedFolder] = useState(0); // The chosen folder
  const [folders, setFolders] = useState([]); // List of available folders
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState("")

  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [contractToDelete, setContractToDelete] = useState(null);
  const filteredContracts = useMemo(() => {
    if (!files || files.length === 0) return []; // Handle empty files
    return files.filter((file) => {
      if (!file?.pdf_name) return false; // Skip invalid data
      return file.pdf_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [files, searchQuery]);

  // Calculate the total number of pages
  const totalPages = useMemo(() => {
    const totalItems = folders.length + files.length; // Total number of items
    return Math.ceil(totalItems / rowsPerPage); // Total pages
  }, [folders, files, rowsPerPage]);

  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };

  const handleApplyFilters = (newFilters, files, folders) => {
    // Use the updated filters to decide visibility of files and folders
    const filteredFiles = files.filter((file) => {
      if (newFilters.folder && file.isFolder) return true;
      if (newFilters.normalDocument && !file.isFolder) return true;
      if (newFilters.contract && file.type === "contract") return true;
      if (newFilters.invoice && file.type === "invoice") return true;
      return false;
    });

    const filteredFolders = folders.filter(() => newFilters.folder);

    setFiles(filteredFiles); // Update visible files
    setFolders(filteredFolders); // Update visible folders
  };

  const handleExport = async () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    if (selectedContracts.length === 0) {
      showPopup("Error", "Please select at least one document to export.");
      return;
    }

    setExportInProgress(true);

    try {
      // Separate contract and invoice keys from selectedContracts
      // const contractKeys = selectedContracts
      //   .filter((item) => item.type === "contract")
      //   .map((item) => item.id);

      // const invoiceKeys = selectedContracts
      //   .filter((item) => item.type === "invoice")
      //   .map((item) => item.id);

      // // Ensure payload is always sent in the correct format
      // const payload = {
      //   contract_keys: contractKeys || [],
      //   invoice_keys: invoiceKeys || [],
      // };

      // console.log("Payload to be sent:", payload);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/export-doc/`, // Updated API endpoint
        {
          contract_keys: selectedContracts, // Send selected contract keys
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Ensure the response is treated as a file download
        }
      );

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "exported_documents.csv"); // Updated file name
      document.body.appendChild(link);
      link.click();

      showPopup("Success", "Documents exported successfully.");
    } catch (error) {
      if (error.response) {
        const message =
          error.response.data.msg || "Failed to export documents.";
        showPopup("Error", message);
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    } finally {
      setExportInProgress(false);
    }
  };

  // Function to handle clicks outside
  const handleClickOutside = (event) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      setIsActionMenuOpen(null); // Close the menu when clicking outside
    }
  };

  // Adding event listener when the component mounts
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleCancel = () => {
    // Clear selected contracts
    setSelectedContracts([]);

    // Close any active modals or popups
    setIsModalOpen(false); // Close the upload modal
    setIsFilterModalOpen(false); // Close the filter modal
    setPopupVisible(false); // Close the popup if visible

    // Reset any additional state as needed
    setConfirmDeleteVisible(false); // Close delete confirmation popup
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page on change
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      scrollToTop(); // Scrolls the table to the top
    }
  };

  const scrollToTop = () => {
    if (tbodyRef.current) {
      tbodyRef.current.scrollTop = 0; // Scroll the <tbody> to the top
    }
  };

  // const handleDropdownSelection = (folder) => {
  //   console.log("Folder selected:", folder.id, folder.name); // Debugging
  //   setSelectedFolder(String(folder.id)); // Ensure `selectedFolder` is always a string
  //   setDropdownOpen(false); // Close dropdown
  // };

  const handleBulkDeleteConfirmation = () => {
    if (selectedContracts.length === 0) {
      showPopup("Error", "No files or folders selected for bulk deletion.");
      return;
    }

    // Open the confirmation modal
    setPopupTitle("Confirm Bulk Deletion");
    setPopupMessage(
      "Are you sure you want to delete the selected items? This action cannot be undone."
    );
    setConfirmDeleteVisible(true);
  };

  const fetchFolders = async () => {
    if (!token) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/folders/`, // Replace with your actual endpoint
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setFolders(response.data); // Update the folders state
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  const fetchContracts = async () => {
    if (!token) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/files-and-folders/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Fetched dataaaaaaaaaaa:", response.data);

      // Destructure the files and folders from the response
      const { files, folders } = response.data;

      // Set the files and folders in the state
      setFiles(files || []); // Ensure default to an empty array if undefined
      setFolders(folders || []); // Ensure default to an empty array if undefined
    } catch (error) {
      console.error("Error fetching files and folders:", error);
    }
  };

  const handleSelectAll = () => {
    if (selectedContracts.length === files.length) {
      setSelectedContracts([]); // Deselect all
    } else {
      setSelectedContracts(files); // Select all visible files
    }
  };

  const paginatedData = useMemo(() => {
    const combined = [...folders, ...files];

    // Sort by the `uploaded_at` or `created_at` field in descending order
    combined.sort((a, b) => {
      const dateA = new Date(a.uploaded_at || a.created_at || 0);
      const dateB = new Date(b.uploaded_at || b.created_at || 0);
      return dateB - dateA; // Most recent first
    });

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return combined.slice(startIndex, endIndex);
  }, [folders, files, currentPage, rowsPerPage]);

  const filteredPaginatedData = useMemo(() => {
    if (!searchQuery.trim()) return paginatedData; // No search query, return original data
    return paginatedData.filter((item) => {
      const name = item?.name?.toLowerCase() || ""; // Extract name and ensure it's a string
      return name.includes(searchQuery.toLowerCase()); // Match against the search query
    });
  }, [paginatedData, searchQuery]);

  console.log("object22", selectedFolder)
  const handleCheckboxChange = (file) => {
    const isSelected = selectedContracts.some((item) => item.id === file.id);

    if (isSelected) {
      setSelectedContracts(
        selectedContracts.filter((item) => item.id !== file.id)
      );
    } else {
      setSelectedContracts([...selectedContracts, file]); // Add the entire file object
    }
  };

  useEffect(() => {
    // Check if folders and files are empty initially
    if (!folders.length && !files.length) {
      fetchFolders();
      fetchContracts();
    }
  }, []);

  const handleUploadSuccess = () => {
    // fetchContracts();
    fetchContracts(); // Re-fetch to get the updated list
    fetchFolders();
    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();
  };

  // const handlePageChange = (event) => {
  //   setCurrentPage(Number(event.target.value));
  // };

  const toggleActionMenu = (index) => {
    setIsActionMenuOpen(isActionMenuOpen === index ? null : index);
  };

  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const CopyToFolderModal = ({
    isOpen,
    onClose,
    document,
    folders,
    onCopy,
  }) => {
    // const [selectedFolder, setSelectedFolder] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSave = () => {
      console.log("object", selectedFolder)
      if (!selectedFolder) {
        setErrorMessage("Please select a folder."); // Show an error message if no folder is selected
        return;
      }

      // Find the selected folder name
      const folder = folders.find((folder) => folder.id === selectedFolder);
      setFileId(selectedFolder);
      if (folder) {
        setSuccessFolderName(folder.name); // Set the folder name for the popup
      }

      // Trigger the copy action and show the success popup
      onCopy(document.id, selectedFolder);
      setIsSuccessPopupVisible(true); // Show the success popup
      onClose(); // Close the main modal
      setSelectedFolderName("")
      setSelectedFolder(0)
    };

    const handleRenameFolder = async (folderId, newName) => {
      if (!newName) {
        showPopup("Error", "Folder name is required.");
        return;
      }

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v1/readpdf/rename-folder/${folderId}/`,
          { new_name: newName },
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setFolders((prevFolders) =>
            prevFolders.map((folder) =>
              folder.id === folderId ? { ...folder, name: newName } : folder
            )
          );
          setIsRenameFolderModalOpen(false); // Close modal
          showPopup("Success", "Folder renamed successfully.");
        }
      } catch (error) {
        console.error("Error renaming folder:", error);
        showPopup("Error", "Failed to rename folder. Please try again.");
      }
    };

    return isOpen ? (
      <div className="copy-modal-overlay">
        <div className="copy-modal">
          <h2>Copy to Folder</h2>
          <p>Copy into any folder and organize your documents</p>
          <h3>Choose Folder</h3>
          <div className="select-container">
            <div
              className="custom-select"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <div className="selected-option">
                {(folders.length > 0 && selectedFolder !== 0)
                  ? folders.find(
                      (folder) => folder.id === selectedFolder
                    )?.name || "Folder Not Found"
                  : selectedFolderName
                  ? selectedFolderName
                  : "Select a folder"}
              </div>

              <span className="select-icon">
                <img src="/copyToFolder-icon.svg" alt="Dropdown Icon" />
              </span>
            </div>

            {dropdownOpen && (
              <div className="custom-dropdown-options">
                {folders.map((folder) => (
                  <div
                    key={folder.id}
                    className="custom-dropdown-option"
                    onClick={() => {
                      console.log("Folder selected:", folder.id); // Debugging
                      console.log("Selected folder name:", folder.name); // Debugging
                      setSelectedFolder(folder.id); // Ensure it's a string
                      setSelectedFolderName(folder.name)
                      setDropdownOpen(false);
                    }}
                  >
                    {folder.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="copy-modal-actions">
            <button onClick={onClose}>Cancel</button>
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    ) : null;
  };

  const RenameModal = ({ isOpen, onClose, document, onRename }) => {
    const [newName, setNewName] = useState(document?.pdf_name || "");

    useEffect(() => {
      if (document) setNewName(document.pdf_name); // Set the current name
    }, [document]);

    const handleSave = () => {
      if (newName.trim()) {
        onRename(document.id, newName.trim());
        onClose();
      }
    };

    return isOpen ? (
      <div className="rename-modal-overlay">
        <div className="rename-modal">
          <h2>Rename Document</h2>
          <p>Create a Custom Name for your Documents</p>
          <h3>Title</h3>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter new name"
          />
          <div className="rename-modal-actions">
            <button onClick={onClose}>Cancel</button>
            <button onClick={handleSave}>Save</button>
          </div>
        </div>
      </div>
    ) : null;
  };

  const handleRename = async (documentId, newName) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/rename-doc/${documentId}/`,
        { new_name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the new document name
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.id === documentId
              ? { ...file, pdf_name: response.data.new_name }
              : file
          )
        );
        window.location.reload();

        showPopup("Success", "Document renamed successfully.");
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to rename document: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to rename document: No response received from the server."
        );
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleRenameFolder = async (folderId, newName) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/rename-folder/${folderId}/`,
        { new_name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the new folder name
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder.id === folderId
              ? { ...folder, folder_name: response.data.folder_name }
              : folder
          )
        );

        showPopup("Success", "Folder renamed successfully.");
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to rename folder: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to rename folder: No response received from the server."
        );
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleCopyToFolder = async (folderId, fileIds) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/add-files/${folderId}/`,
        { file_ids: fileIds },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // Optionally, refresh the file/folder list or update the state if needed.
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to copy files: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to copy files: No response received from the server."
        );
      } else {
        showPopup("Error", "An unexpected error occurred. Please try again.");
      }
    }
  };

  // useEffect(() => {
  //   const fetchFolders = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_API_URL}/api/v1/folders/`, // Adjust the endpoint
  //         {
  //           headers: { Authorization: `Token ${token}` },
  //         }
  //       );
  //       setFolders(response.data); // Set the fetched folders
  //     } catch (error) {
  //       console.error("Error fetching folders:", error);
  //     }
  //   };

  //   fetchFolders();
  // }, [token]);

  const handleViewMore = (fileKey) => {
    if (!fileKey) {
      console.error("File key is missing!");
      alert("File key is not available.");
      return;
    }
    console.log("Navigating with contractKey:", fileKey);
    navigate("/Contract/contract-details", { state: { contractKey: fileKey } });
  };

  const enableEdit = (contract) => {
    if (contract) {
      setIsActionMenuOpen(false);
      setEditContractId(contract.id);
      setEditedContractData({ ...contract });
    } else {
      setEditContractId(null); // Reset edit mode
      setEditedContractData({});
    }
  };

  const handleEditChange = (field, value) => {
    setEditedContractData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const goToPage = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return; // Prevent out-of-range pages
    setCurrentPage(pageNumber);
  };

  const handleIncreasePage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handleDecreasePage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  var intervalId;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");

  const location = useLocation();

  const contractKey = location.state?.contractKey;

  useEffect(() => {
    if (contractKey) {
      console.log("Received contractKey:", contractKey);
      // Fetch contract details or perform other actions with the key
    } else {
      console.error("No contractKey provided in navigation state.");
    }
  }, [contractKey]);

  // Helper function to format date and time
  // Helper function to format date and time
  const formatDateTimeWithRelative = (dateTimeString) => {
    if (!dateTimeString) return "N/A";

    const date = new Date(dateTimeString);
    const now = new Date();

    // Check if the date is valid
    if (isNaN(date.getTime())) return "N/A";

    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    let relativeTime;
    if (diffInMinutes < 1) {
      relativeTime = "Just now";
    } else if (diffInMinutes < 60) {
      relativeTime = `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      relativeTime = `${diffInHours} hours ago`;
    } else {
      relativeTime = `${diffInDays} days ago`;
    }

    const absoluteDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    return `${relativeTime} (${absoluteDate})`;
  };

  // console.log(location.state.file, "location ************88888i");

  const handleFile = (e) => {
    return new Promise((resolve, reject) => {
      let selectedFile = e.target.files[0];
      // console.log("selectedFile", e.target.files[0]);

      const fileType = ["application/pdf"];

      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsDataURL(selectedFile);
          reader.onload = (e) => {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
        } else {
          // toast.warn("Invalid file type", {
          //   autoClose: 2000,
          // });
          // reject(new Error("Invalid file type"));
        }
      } else {
        reject(new Error("No file selected"));
      }
    });
  };

  const handleBulkDelete = async () => {
    if (!token) {
      showPopup("Error", "Session expired. Please log in again.");
      return;
    }

    if (selectedContracts.length === 0) {
      showPopup("Error", "No files or folders selected for deletion.");
      return;
    }

    try {
      // Extract folder and document IDs from selectedContracts
      const folderIds = selectedContracts
        .filter((item) => item.document_type === "folder")
        .map((item) => item.id);

      const documentIds = selectedContracts
        .filter((item) => item.document_type !== "folder")
        .map((item) => item.id);

      // Ensure at least one item is selected
      // if (folderIds.length === 0 && documentIds.length === 0) {
      //   showPopup("Error", "No valid items selected for deletion.");
      //   return;
      // }

      // Prepare the payload
      const payload = {
        folder_ids: folderIds,
        document_ids: documentIds,
      };

      console.log("Bulk delete payload:", payload); // Debugging

      // Call the API
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/bulk-delete/`,
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { deleted_folders, deleted_documents, failed_collections } =
          response.data;

        // Update UI by removing deleted items
        setFiles((prevFiles) =>
          prevFiles.filter(
            (file) =>
              !deleted_folders.includes(file.id) &&
              !deleted_documents.includes(file.id)
          )
        );

        setSelectedContracts([]); // Clear selection

        // Show success messages
        if (deleted_folders.length > 0 || deleted_documents.length > 0) {
          fetchContracts();
          showPopup(
            "Success",
            `Deleted ${deleted_folders.length} folder(s) and ${deleted_documents.length} document(s).`
          );
        }

        // Handle any failed deletions
        if (failed_collections.length > 0) {
          const failureMessages = failed_collections
            .map(
              (failure) =>
                `ID: ${failure.id} (${failure.type}) - ${failure.error}`
            )
            .join("\n");
          showPopup("Some Deletions Failed", failureMessages);
        }
      } else {
        showPopup(
          "Error",
          "Failed to delete selected items. Please try again."
        );
      }
    } catch (error) {
      console.error("Error during bulk delete:", error);
      showPopup("Error", "An unexpected error occurred during deletion.");
    } finally {
      setConfirmDeleteVisible(false); // Close confirmation modal
    }
  };

  const combinedFilesAndFolders = useMemo(() => {
    return [...folders, ...files].map((item) => ({
      ...item,
      isFolder: !!item.documents, // Add a flag to differentiate folders
    }));
  }, [folders, files]);

  const handleResponse = (res, file) => {
    // console.log("res data is ---", res)
    if (!res || !res.data) {
      console.error("Response or response data is undefined");
      return setStatus("Failed to get a valid response from the server");
    }

    if (res.data.pdf_key) {
      console.log("PDF key received:", res.data.pdf_key);
      localStorage.setItem("pdfKey", res.data.pdf_key); // Store pdf_key regardless of status
    }

    // console.log(res.data.status, "data status is------")
    switch (res.data.status) {
      case "STARTING":
        console.log("STARTING: PDF key received:", res.data.pdf_key);
        // localStorage.setItem("pdfKey", res.data.pdf_key);
        return setStatus("Uploading Pdf ..");
      case "LOADING":
        return setStatus("Loading Pdf ...");
      case "ANALYZING":
        return setStatus("Analysing Pdf ...");
      case "SUMMARIZING":
        return setStatus("Summarizing Pdf ...");
      case "PENDING":
        return setStatus("Maybe Asynchronous task failed...");
      case "FAILED":
        return setStatus("Failed due to some issues, please try again!");
      case "Completed":
        clearInterval(intervalId);
        setStatus("Upload successful!");
        // console.log(res.data, 'This is updated data')
        return location.pathname === "/test"
          ? navigate("/test/c", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            })
          : navigate("/chat", {
              state: {
                summary: res.data.data.summary,
                questions: res.data.data.examples,
                file: file,
              },
            });
      default:
        console.error(`Unknown status from server ${res.data.status}`);
        throw new Error(`Unknown status from server ${res.data.status}`);
    }
  };

  const handleDelete = (pdfId) => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }

    setPopupTitle("Confirm Deletion");
    setPopupMessage(
      "Are you sure you want to delete this PDF? This action cannot be undone."
    );
    // setConfirmDeleteVisible(true);
    setContractToDelete(pdfId); // Save the ID of the PDF to be deleted
  };

  const handleEditSave = async (contractId) => {
    if (!editedContractData.contract_name) {
      showPopup("Error", "Contract name is required.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-name/`,
        {
          contract_key: editedContractData?.key,
          new_contract_name: editedContractData?.contract_name,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFiles((prevFiles) =>
          prevFiles.map((filee) =>
            filee.id === filee ? editedContractData : filee
          )
        );
        setEditContractId(null);
        showPopup("Success", "Contract updated successfully.");
      }
    } catch (error) {
      if (error.response) {
        showPopup(
          "Error",
          `Failed to update contract: ${
            error.response.data.msg || error.response.statusText
          }`
        );
      } else if (error.request) {
        showPopup(
          "Error",
          "Failed to update contract: No response received from the server."
        );
      } else {
        showPopup("Error", "Failed to update contract. Please try again.");
      }
    }
  };

  const confirmDelete = async () => {
    if (!contractToDelete || !token) {
      showPopup("Error", "No PDF selected for deletion.");
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/pdfs/${contractToDelete}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 204) {
        // Assuming 204 means successful deletion
        setFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== contractToDelete)
        );
        showPopup("Success", "PDF deleted successfully.");
      } else {
        showPopup("Error", "Failed to delete the PDF. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting PDF:", error);
      showPopup("Error", "Failed to delete PDF. Please try again.");
    } finally {
      setConfirmDeleteVisible(false);
      setContractToDelete(null); // Clear the selected ID
    }
  };

  useEffect(() => {
    if (editContractId) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editContractId]);

  const upload = async (e) => {
    // Check if a file is actually selected
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      console.log("No file selected");
      return; // Exit the function if no file is selected
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let file = null;
    handleFile(e).then((res) => {
      file = res;
    });
    // console.log("formData", formData);
    try {
      // let file = await handleFile(e)
      const token = localStorage.getItem("token");
      // console.log(token, "this is token")
      setLoading(true);
      setStatus("Uploading...");
      await axios
        .post(
          // process.env.REACT_APP_BASE_URL + "/api/v1/readpdf/qdrant-async-fast/",
          process.env.REACT_APP_BASE_URL +
            `/api/v1/readpdf/qdrant-upload-async/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("API Response:", res); // Debugging the full response
          // handleResponse(res, file);
          // intervalId = setInterval(() => getPdfStatus(file), 1000);
        });
    } catch (err) {
      setLoading(false);
      setStatus("Error during file upload");
      if (
        err.response &&
        err.response.data &&
        err.response.data.msg === "Unsupported file format"
      ) {
        toast.warn("Unsupported file format", { autoClose: 2000 });
      } else {
        console.error("Error during file upload:", err);
        toast.error("An error occurred during file upload", {
          autoClose: 2000,
        });
      }
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/delete-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => folder.id !== folderId)
        );
        setIsDeleteFolderModalOpen(false); // Close delete modal
        showPopup("Success", "Folder deleted successfully.");
      } else {
        showPopup("Error", "Failed to delete folder. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      showPopup("Error", "Failed to delete folder. Please try again.");
    }
  };

  return (
    <div className="document-full-container">
      <div className="documents-header">
        <Header name="Documents" icon={""} />
      </div>

      <div className="document-contract-table-container">
        <div className="document-contract-table-header">
          <div className="document-search-filter-container">
            <div className="document-search-box">
              <img src="search.svg" alt="" />
              <input
                type="text"
                placeholder="Search Documents Here"
                className="document-input-space"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value.trim()); // Trimming extra spaces
                }}
              />
            </div>
            <button
              className="document-filter-button-folder"
              onClick={toggleFilterModal}
            >
              <img src="/filter.svg" alt="Filter Icon" />
            </button>
          </div>
          {selectedContracts.length > 0 ? (
            <div className="document-button-cat">
              <button
                onClick={handleExport}
                disabled={exportInProgress || selectedContracts.length === 0}
              >
                <img src="/export-icon-blue.svg" alt="Export Icon" />
                {exportInProgress ? "Exporting..." : "Export"}
              </button>
              <button
                onClick={handleBulkDeleteConfirmation} // Opens the confirmation modal
                disabled={selectedContracts.length === 0} // Disabled if no items selected
              >
                <img src="/delete-icon-red.svg" alt="Delete Icon" />
                Delete
              </button>

              <button onClick={handleCancel} className="cancel-bulk">
                <img src="/cancel_icon.svg" alt="Cancel Icon" />
              </button>
            </div>
          ) : (
            <div className="document-upload-section">
              <button
                className="document-upload-button1"
                onClick={() => setIsCreateFolderModalOpen(true)}
              >
                <img src="/upload-doc-create.svg" alt="Upload Icon" /> Create
                Folder
              </button>

              <button
                className="document-upload-button2"
                // onClick={() => document.getElementById("dropzone-file").click()}
                onClick={toggleModal}
              >
                <img src="/upload-icon-doc.svg" alt="Upload Icon" /> Upload
              </button>

              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={upload} // Your existing upload function
                // onClick={openModal}
                // Your existing openModal function
              />

              {loading && (
                <StatusModal
                  closeModal={closeModal}
                  status={status}
                  loading={loading}
                />
              )}
            </div>
          )}
        </div>

        <div className="container">
          <table className="contract-table">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="document-custom-checkbox"
                    onChange={handleSelectAll}
                    checked={
                      selectedContracts.length === files.length &&
                      files.length > 0
                    }
                  />
                </th>
                <th>File Name</th>
                <th>File Type</th>
                <th>Size</th>
                <th>Uploaded Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody ref={tbodyRef}>
              {filteredPaginatedData.length > 0 ? (
                filteredPaginatedData.map((item, index) => (
                  <tr
                    key={`${item.documents ? "folder" : "file"}-${index}`}
                    onClick={(e) => {
                      if (
                        e.target.type !== "checkbox" &&
                        !e.target.classList.contains("action-button")
                      ) {
                        e.stopPropagation();
                        if (item.documents) {
                          const pdfUrl = item.documents[0]?.file_url;
                          const pdfKey = item.documents[0]?.key;
                          navigate(`/folder/?folderId=${item.id}`, {
                            state: { folderId: item.id, folderName: item.name },
                          });
                        } else {
                          navigate("/chat", {
                            state: { pdfUrl: item.pdf_url, pdfKey: item.key },
                          });
                        }
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="h-[61px] align-middle">
                      <input
                        type="checkbox"
                        className="document-custom-checkbox"
                        checked={selectedContracts.some((selectedItem) =>
                          item.documents
                            ? selectedItem.id === item.id
                            : selectedItem.id === item.id
                        )}
                        onChange={() =>
                          handleCheckboxChange({
                            ...item,
                            document_type: item.documents ? "folder" : "file",
                          })
                        }
                      />
                    </td>
                    <td className="file-name-cell">
                      {item.documents ? (
                        <div
                          className="file-icon-and-name"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/folder/?folderId=${item.id}`, {
                              state: {
                                folderId: item.id,
                                folderName: item.name,
                              },
                            });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src="/folder-icon.svg"
                            alt="Folder Icon"
                            className="file-icon"
                          />
                          <div className="file-details">
                            <div className="file-name">
                              {item.name || "N/A"}
                            </div>
                            <div className="file-type">
                              {item.doc_type || "N/A"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="file-icon-and-name">
                          <img
                            src="/pdf.svg"
                            alt="PDF Icon"
                            className="file-icon"
                          />
                          <div className="file-details">
                            <div className="file-name">
                              {item.name || "N/A"}
                            </div>
                            <div className="file-type">
                              {item.document_type
                                ? item.document_type.charAt(0).toUpperCase() +
                                  item.document_type.slice(1)
                                : "Document"}
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                    <td style={{ color: "#B793ED" }}>
                      {item.documents ? "Folder" : item?.document_type}
                    </td>
                    <td>
                      {item.documents
                        ? item.folder_size || "N/A"
                        : item.document_size || "N/A"}
                    </td>
                    <td>
                      <div className="text-left  leading-5">
                        <span className="">{item.time_ago || "N/A"}</span>
                        <br />
                        <span className="">
                          {item.created_at || item.uploaded_at
                            ? new Date(
                                item.created_at || item.uploaded_at
                              ).toLocaleDateString()
                            : new Date().toLocaleDateString()}
                        </span>
                      </div>
                    </td>

                    <td className="chat-button-cell">
                      <div
                        className="chat-button-doc"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item.documents) {
                            navigate("/Contract/FolderChat", {
                              state: {
                                folderId: item.id,
                                folderName: item.name || "N/A",
                                pdfList: item.documents.map((doc) => ({
                                  pdfKey: doc.id,
                                  pdfUrl: doc.file_url,
                                  name: doc.name,
                                })),
                              },
                            });
                          } else {
                            navigate("/chat", {
                              state: { pdfUrl: item.pdf_url, pdfKey: item.key },
                            });
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="/chat-icon.svg"
                          alt="Chat Icon"
                          className="chat-icon"
                        />
                        <span>Chat</span>
                      </div>
                    </td>
                    <td>
                      <img
                        src="./action.svg"
                        alt="Actions"
                        width="20"
                        height="20"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleActionMenu(index);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      {isActionMenuOpen === index && (
                        <div
                          className={`document-action-menu ${
                            item.documents ? "folder-options" : ""
                          }`}
                          ref={actionMenuRef}
                        >
                          {item.documents ? (
                            <>
                              <div
                                className="document-action-item "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentFolder(item);
                                  setIsRenameFolderModalOpen(true);
                                }}
                              >
                                <img
                                  src="./edit.svg"
                                  alt="Edit Icon"
                                  className="document-action-icon"
                                />
                                <p>Rename Folder</p>
                              </div>
                              <div
                                className="document-action-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setFolderToDelete(item.id);
                                  setIsDeleteFolderModalOpen(true);
                                }}
                              >
                                <img
                                  src="./delete.svg"
                                  alt="Delete Icon"
                                  className="document-action-icon"
                                />
                                <p>Delete Folder</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="document-action-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentDocument(item);
                                  setIsCopyModalOpen(true);
                                }}
                              >
                                <img
                                  src="./folder-copy.svg"
                                  alt="Copy to Folder Icon"
                                  className="document-action-icon"
                                />
                                <p>Copy to Folder</p>
                              </div>
                              <div
                                className="document-action-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentDocument(item);
                                  setIsRenameModalOpen(true);
                                }}
                              >
                                <img
                                  src="./edit.svg"
                                  alt="Edit Icon"
                                  className="document-action-icon"
                                />
                                <p>Rename</p>
                              </div>
                              <div
                                className="document-action-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(item.id);
                                }}
                              >
                                <img
                                  src="./delete.svg"
                                  alt="Delete Icon"
                                  className="document-action-icon"
                                />
                                <p>Delete</p>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-contracts-icon">
                  <img src="/no-contracts.svg" alt="No Data Available" />
                  <p className="no-contracts-para">
                    Looks like your table is empty!
                  </p>
                </div>
              )}
            </tbody>
          </table>
          <div className="pagination" style={{ bottom: "10px" }}>
            <div className="document-pagination-dropdown">
              <select
                value={currentPage}
                onChange={(e) => handlePageChange(Number(e.target.value))}
                className="document-page-selector"
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
            <p className="document-contentDown">of {totalPages} pages</p>
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
            >
              <img
                src="decrease.svg"
                alt="Previous Page"
                style={{ height: "15px", width: "15px" }}
              />
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              <img
                src="increase.svg"
                alt="Next Page"
                style={{ height: "15px", width: "15px" }}
              />
            </button>
          </div>
        </div>
      </div>

      {popupVisible && (
        <div className="document-popup-overlay">
          <div className="document-popup-box">
            <h2>{popupTitle}</h2>
            <p>{popupMessage}</p>
            <button
              onClick={closePopup}
              className="document-popup-close-button"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {confirmDeleteVisible && (
        <div className="document-popup-overlay">
          <div className="document-popup-box-delete">
            {" "}
            {/*in future success icon i can add here */}
            <h2>Confirm Bulk Deletion</h2>
            <p>
              Are you sure you want to delete the selected files and folders?
              This action cannot be undone.
            </p>
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {/* Trigger handleBulkDelete on confirmation */}
              <button
                onClick={handleBulkDelete}
                className="document-popup-close-delete"
              >
                Yes, Delete
              </button>
              {/* Close modal on cancel */}
              <button
                onClick={() => setConfirmDeleteVisible(false)}
                className="document-popup-close-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {contractToDelete && (
        <div className="document-popup-overlay">
          <div className="document-popup-box-delete">
            <h2>Confirm Deletion</h2>
            <p>
              Are you sure you want to delete this PDF? This action cannot be
              undone.
            </p>
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                onClick={confirmDelete} // Call confirmDelete for simple deletion
                className="document-popup-close-delete"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setContractToDelete(null)} // Close the popup
                className="document-popup-close-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <UploadModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUploadSuccess={handleUploadSuccess}
        type={"document"}
      />
      <RenameModal
        isOpen={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
        document={currentDocument}
        onRename={(documentId, newName) => handleRename(documentId, newName)}
      />

      <CopyToFolderModal
        isOpen={isCopyModalOpen}
        onClose={() => setIsCopyModalOpen(false)}
        document={currentDocument} // Current document to be copied
        folders={folders} // List of folders
        onCopy={(documentId, folderId) =>
          handleCopyToFolder(folderId, [documentId])
        } // Call with document ID and folder ID
      />

      {/* <CopyToFolderModal
  isOpen={isCopyModalOpen}
  onClose={() => setIsCopyModalOpen(false)}
  document={currentDocument} // Current document to be copied
  folders={folders} // List of folders
  onCopy={(documentId, folderId) => handleCopyToFolder(folderId, [documentId])} // Call with document ID and folder ID
/> */}

      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onApply={(filters) => {
          handleApplyFilters(filters, files, folders); // Pass current files and folders along with filters
        }}
        setFiles={setFiles} // Allows `FilterModal` to modify files directly if needed
        setFolders={setFolders} // Allows `FilterModal` to modify folders directly if needed
      />

      <CreateFolderModal
        isOpen={isCreateFolderModalOpen}
        onClose={() => setIsCreateFolderModalOpen(false)}
        onCreateSuccess={fetchFolders} // Refresh folder list after creation
      />
      <RenameModal
        isOpen={isRenameFolderModalOpen}
        onClose={() => setIsRenameFolderModalOpen(false)}
        document={currentFolder} // Pass current folder details
        onRename={handleRenameFolder} // Pass the rename handler
      />

      <button
        onClick={handleBulkDelete}
        disabled={selectedContracts.length === 0}
      >
        <img src="/delete-icon-red.svg" alt="Delete Icon" />
      </button>

      {isSuccessPopupVisible && (
        <div className="success-popup-overlay">
          <div className="success-popup-box">
            <div className="stacked-icons-container">
              <img
                src="/folder-copy-tick.svg"
                alt="Tick"
                className="icon tick-icon"
              />
              <img
                src="/folder-copy-circle.svg"
                alt="Circle"
                className="icon circle-icon"
              />
              <img
                src="/folder-copied-circle.svg"
                alt="Copied"
                className="icon copied-icon"
              />
            </div>

            <h2 className="folder-uploaded-success">
              Folder Successfully Copied
            </h2>
            <p className="folder-folderName">
              Your documents have been copied to {successFolderName}.
            </p>
            <div className="copy-folder-last">
              <button
                onClick={() => navigate(`/folder/?folderId=${file_ids}`)}
                className="copy-folder-view"
              >
                View Folder
              </button>

              <button
                onClick={() => setIsSuccessPopupVisible(false)}
                className="copy-folder-done"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteFolderModalOpen && (
        <div className="document-popup-overlay">
          <div className="document-popup-box-delete">
            <h2>Confirm Folder Deletion</h2>
            <p>
              Are you sure you want to delete this folder? This action cannot be
              undone.
            </p>
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => handleDeleteFolder(folderToDelete)}
                className="document-popup-close-delete"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setIsDeleteFolderModalOpen(false)}
                className="document-popup-close-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFilePage;
