import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import './signup.css'
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { v4 as uuid } from "uuid";
import { FcGoogle } from "react-icons/fc";
import { MdAccountCircle } from "react-icons/md";
import OtpPage from "./OtpSection/OtpPage";
import axios from "axios";
import { useNavigate } from "react-router";
import GoogleSignupButton from "../../components/GoogleSignupButton";
import logo from "../../Images/Logo.png"
import loginImage from '../../Images/loginImage.png'
import errorEmailLogo from '../../Images/emailerror.png'
import { Link } from "react-router-dom";
import GoogleSignInButton from "../../components/GoogleSignInButton";

const SignupPage = () => {
  const navigate = useNavigate();
  const [signupDetails, setSignupDetails] = useState({
    email: "",
    password: "",
    first_name: "",
    full_name:"", // Added to keep track of confirmPassword

  });

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredPassword, setIsHoveredPassword] = useState(false); // Hover state for password error icon


  // Password validation regular expressions
  const passwordRegex = {
    minLength: /(?=.{8,})/,
    hasUppercase: /(?=.*[A-Z])/,
    hasNumber: /(?=.*\d)/,
  };

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasUppercase: false,
    hasNumber: false,
  });

  // Modelsection
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState("");

  // const openModal = () => {
  //   setModalEmail(signupDetails.email);
  //   setShowModal(true);
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [showTermsError, setShowTermsError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false); // State to control OTP modal


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // verify signin button
  const [verified, setVerified] = useState(false);
  const loginMessage = () => {
    toast.success("Login successfully!", {
      autoClose: 2000,
    });
  };

  // const handleSignupDetailsChange = (event) => {
  //   const { name, value } = event.target;
  //   setSignupDetails((prevSignupDetails) => ({
  //     ...prevSignupDetails,
  //     [name]: value,
  //   }));
  // };

 
  const handleSignupDetailsChange = (event) => {
    const { name, value } = event.target;
    
    // Handle password field validation
    if (name === "password") {
      const newPassword = value;
      setPasswordValidation({
        minLength: passwordRegex.minLength.test(newPassword),
        hasUppercase: passwordRegex.hasUppercase.test(newPassword),
        hasNumber: passwordRegex.hasNumber.test(newPassword),
      });
      setShowPasswordValidation(false);
    }
  
    // Update fields (first_name, email, password)
    setSignupDetails((prevSignupDetails) => ({
      ...prevSignupDetails,
      [name]: value,
    }));
  
    // Reset errors and button state
    setEmailError("");
    setIsBtnDisabled(false);
  };
  
  


  // console.log(process.env.REACT_APP_API_URL , 'this is url ')
  const [token, setToken] = useState("");

  const handleSignUp = async () => {
    setIsBtnDisabled(true);
    setIsFormSubmitted(true);
    setShowPasswordValidation(true);
  
  
    if (!signupDetails.full_name.trim()) {
      toast.error("Full Name is required.");
      setIsBtnDisabled(false);
      return;
  }
  if (!passwordValidation.minLength) {
      return; 
  }
  
    
    setIsLoading(true);
  
    const payload = {
      email: signupDetails.email,
      password: signupDetails.password,
      full_name: signupDetails.full_name, 
  };
  
  
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/accounts/signup/",
        payload
      );
  
      if (response.data.status === 400) {
        setEmailError("Email Already Exist");
      } else if (response.data.status === 200) {
        toast.success("Signup successful!", { autoClose: 2000 });

        window.localStorage.setItem("token", response.data.data.token);
        window.localStorage.setItem("email", signupDetails.email);
        window.localStorage.setItem("full_name", response.data.data.full_name); // Save full_name in localStorage

        
        // openModal();
        setToken(response.data.data.token);
        localStorage.setItem("lastname", response.data.data.full_name);

        // If Remember Me is checked, store the email in localStorage
        localStorage.setItem("email", signupDetails.email);

        // Navigate to OTP page with email and token
        navigate("/otp", {
          state: {
            email: signupDetails.email,
            token: response.data.data.token,
            
          },
        });console.log(response.data.data.token); 
      }
    } catch (error) {
      toast.error("Signup failed. Please try again.");
      console.error("Signup error:", error);
    } finally {
      setIsLoading(false); 
    }
  };
  

  useEffect(() => {
    setIsBtnDisabled(false)
  }, []);

  
  
  // const handleNavigateToLogin = () => {
  //   navigate('/signin');
  // };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   if (token) {
  //     setVerified(true);
  //   }
  // }, []);

  // If verified is true, navigate to "/uploadFile"
  // useEffect(() => {
  //   if (verified) {
  //     navigate("/complete-account");
  //   }
  // }, [verified, navigate]);
  return (
  <div className="bg-white ">
   
    <div className=" sigin-container bg-[#FEF9F7]" >
      <span className="main">
      <div className="logo-container">
    <img
      src="/docusensasign.svg"
      alt="docuSensa logo"
      className="w-full h-full object-contain"
    />
  </div>
      </span>

      <section className="flex justify-center items-center">
        <div className="w-[25.19rem] h-[35rem] border border-[rgba(0,0,0,0.10)] bg-white rounded-[12px] flex flex-col p-6 mt-[115px] ml-[-40px]">
          <div>
            <h2 className="welcome-signin">Get started Now</h2>
            <h3 className="credentials-signin">
            Enter your Credentials to Create an Account
            </h3>
          </div>
          <div>
            <div className="google-signin-button">
            <GoogleSignInButton 
        setVerified={setVerified}
        loginMessage={loginMessage}
      />
              {/* <img
                src="/googleicon.svg"
                alt="Google icon"
                className="google-icon"
              />
              <span className="google-text">Continue with Google</span> */}
            </div>

            {/* <GoogleSignInButton 
        setVerified={setVerified}
        loginMessage={loginMessage} 
      /> */}
          </div>

          <div className="or-style my-6">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-gray-500">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>
          
          <label htmlFor="full_name" className="email-sigin">
  Full Name
</label>
<div className="Email-sigin-box relative flex items-center">
  <img
    src="/fullnameicon.svg"
    alt="FullName icon"
    className="emailsigin-icon w-5 h-5 absolute left-3"
  />
  <div className="line-between-icon absolute left-12 top-1/2 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

  <input
    type="text"
    name="full_name" // Use first_name field
    value={signupDetails.full_name}  // Bind the value to first_name
    onChange={handleSignupDetailsChange} // Handle changes
    className="Email-signup-placeholder border-none outline-none pl-4 ml-14"
    placeholder="Enter your Full Name"
    required
  />

  {/* {emailError && (
    <p className="text-red-500 text-sm mt-1 flex items-center">
      <span className="w-[20px] h-[20px] mr-1">
        <img src={errorEmailLogo} alt="email error logo" />
      </span>{" "}
      {emailError}
    </p>
  )} */}
</div>


          <div>
  <label for="email" className="email-sigin mt-3">
    Email
  </label>
  <div className="Email-sigin-box relative flex items-center">
    <img
      src="/email-signin.svg"
      alt="Email icon"
      className="emailsigin-icon w-5 h-5 absolute left-3"
    />
    <div className="line-between-icon absolute left-12 top-1/2 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

    <input
      type="email"
      name="email"
      value={signupDetails.email} // Bind the value here
      onChange={handleSignupDetailsChange}
      className="Email-signup-placeholder border-none outline-none pl-4 ml-14" // Added padding to the left for the icon
      placeholder={emailError || "Enter your Email"}
      required
    />

{emailError && (
  <div
    className="relative flex items-center"
    onMouseEnter={() => setIsHovered(true)}  // On hover, set state to true
    onMouseLeave={() => setIsHovered(false)} // On mouse leave, set state to false
  >
    <span className="w-[20px] h-[20px] mr-1 cursor-pointer">
      <img src="/error.svg" alt="email error logo" />
    </span>

    {/* Tooltip will only show if hovered */}
    {isHovered && (
    <div className="popup-show-letter email-error-hover">
        <p>{emailError}</p>
    </div>
)}

  </div>
)}
  </div>
</div>





<div>
  <label htmlFor="password" className="password-sigin">
    Password
  </label>
  <div className="password-sigin-box flex items-center border">
    <img
      src="/passkey.svg"
      alt="Password icon"
      className="emailsigin-icon w-5 h-5 ml-3"
    />
    <div className="line-between-icon ml-3.5 mt-8 transform -translate-y-1/2 w-[1px] h-[32px] bg-[rgba(0,0,0,0.10)]"></div>

    <input
      type={showPassword ? "text" : "password"}
      name="password"
      value={signupDetails.password}  // Bind value to the password field
      onChange={handleSignupDetailsChange}
      placeholder={passwordValidation.minLength ? "Enter Your Password" : "Password must be at least 8 characters long"}
      className="password-sigin-placeholder border-none outline-none ml-2"
      required
    />
    {showPasswordValidation && signupDetails.password && !passwordValidation.minLength && (
      <div
        className="relative flex items-center mt-0"
        onMouseEnter={() => setIsHovered(true)}  // On hover, show tooltip
        onMouseLeave={() => setIsHovered(false)} // On mouse leave, hide tooltip
      >
        <span className="w-[20px] h-[20px] mr-1 cursor-pointer">
          <img src="/error.svg" alt="password error logo" />
        </span>

        {/* Tooltip that appears when hovered */}
        {isHovered && (
          <div className="popup-show-letter email-error-hover">
            <p>At least 8 Characters</p>
          </div>
        )}
      </div>
    )}

    <button
      type="button"
      onClick={togglePasswordVisibility}
      className="text-gray-500 ml-9"
    >
      {showPassword ? (
        <IoEyeOutline size={22} />
      ) : (
        <IoEyeOffOutline size={22} />
      )}
    </button>
  </div>

  {/* Password validation messages
  {showPasswordValidation && signupDetails.password && !passwordValidation.minLength && (
    <div>
      <p className="text-sm mt-1 flex items-center">
        <span className="w-[20px] h-[20px] mr-1">
          <img src={errorEmailLogo} alt="password error logo" />
        </span>{" "}
        Password must be at least 8 characters long.
      </p>
    </div>
  )} */}
</div>




<div className="flex items-start">
  <div className="ml-1 text-sm mt-3">
    <label htmlFor="terms" className="policy">
      By clicking create account; I agree to{" "}
      <Link to="/term&condition" className="termsandCondition hover:underline">
        Terms Of Service
      </Link>{" "}
      and{" "}
      <Link to="/privacy" className="termsandCondition hover:underline">
        Privacy Policy
      </Link>.
    </label>
  </div>
</div>


              {/* {showTermsError && (
                  <p className="text-sm mt-1 flex items-center">
                  <span className="w-[20px] h-[20px] mr-1"><img src={errorEmailLogo} alt="email error logo"/></span> Please agree to the Terms & Privacy to continue.
                </p>
                )} */}

<div className="w-[355px] h-[62px] bg-[#8B55DD] rounded-[6px] flex justify-center items-center mt-3">
  <button
    className={`signin-button ${isBtnDisabled ? "notAccepted cursor-not-allowed" : "isTermAccepted"}`}
    onClick={handleSignUp} // Handle signup functionality
    disabled={isBtnDisabled} // Disable if button is disabled
  >
    {isLoading ? <div className="loading"></div> : "Sign Up"}
  </button>
</div>


        </div>
      </section>

      <p className="login-text-sigin mt-2">
      Have an account??{" "}
        <Link to="/signin" className="login-click-singin">
        Log in
        </Link>
      </p>
      
    </div>
   
    </div>
  );
};

export default SignupPage;
