import React, { useState, useEffect } from "react";
import "./filterModal";
import axios from "axios";

const FilterModalForFolder = ({ isOpen, onClose, onApply, setFiles, folderId, src }) => {
  const [token, setToken] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    normalDocument: false,
    contract: false,
    invoice: false,
  });
  const [fileType, setFileType] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [rangeDate, setRangeDate] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const [activeTab, setActiveTab] = useState("FileType"); // Default tab is FileType

  // Handle apply filter
  const handleApply = async () => {
    try {
      const params = {
        file_type: fileType.length > 0 ? fileType.join(",") : undefined, // Join all selected types
        ...(rangeDate
          ? { date_range: rangeDate } // Include date_range if selected
          : {
              from_date: fromDate || undefined, // Include from_date if provided
              to_date: toDate || undefined, // Include to_date if provided
            }),
      };

      console.log("Filters being applied:", params);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/filter-files-in-folder/${folderId}/`,
        {
          params,
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFileType([]); // Reset filters after successful apply
        setFromDate("");
        setToDate("");
        setRangeDate("");
        src === "folder"
          ? setFiles({ documents: response.data.files })
          : setFiles(response.data.files);
      }
    } catch (err) {
      console.log("Error while applying filter:", err);
    }
    onClose();
  };

  // Handle checkbox changes for file type filters
  const handleCheckboxChange = (type) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev, [type]: !prev[type] };

      // Update fileType array based on selected filters
      setFileType(() => {
        const fileTypeMapping = {
          normalDocument: "normal_document",
          contract: "contract",
          invoice: "invoice",
        };

        // Get all currently selected file types
        const selectedFileTypes = Object.keys(updatedFilters)
          .filter((key) => updatedFilters[key]) // Filter only selected types
          .map((key) => fileTypeMapping[key]); // Map to corresponding file types

        return selectedFileTypes; // Return the updated fileType array
      });

      return updatedFilters;
    });
  };

  // Clear all filters
  const clearAllFilters = () => {
    setSelectedFilters({
      normalDocument: false,
      contract: false,
      invoice: false,
    });
    setFileType([]); // Clear all file types
    setFromDate("");
    setToDate("");
    setRangeDate("");
  };

  // Disable the date pickers if the range is selected
  const isDatePickerDisabled = rangeDate !== "";

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay-doc">
      <div className="filter-modal-doc">
        <h2>Filter By</h2>
        <div className="Display">
          <div className="Box-rec">
            <button
              className={`Box-doc ${activeTab === "FileType" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("FileType")}
            >
              <img src="./File-type.svg" alt="" />
              File Type
            </button>
          </div>

          {activeTab === "FileType" && (
            <div className="filter-options">
              <div className="Header-doc-filter-folder">
                <p>Select the type of files to include in the results</p>
              </div>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFilters.normalDocument} // Correctly bind to checked
                  onChange={() => handleCheckboxChange("normalDocument")}
                  className="chekcbox-doc-filter"
                />
                Normal Document
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFilters.contract} // Correctly bind to checked
                  onChange={() => handleCheckboxChange("contract")}
                  className="chekcbox-doc-filter"
                />
                Contract
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={selectedFilters.invoice} // Correctly bind to checked
                  onChange={() => handleCheckboxChange("invoice")}
                  className="chekcbox-doc-filter"
                />
                Invoice
              </label>
            </div>
          )}
        </div>

        <div className="filter-actions-folder">
          <button onClick={clearAllFilters} className="clear-all-filter">
            Clear All
          </button>
          <button onClick={handleApply} className="apply-filter">
            Apply filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModalForFolder;
