import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk for fetching chat responses for contracts
export const fetchChatContract = createAsyncThunk(
  "chatContract/fetchChatContract",
  async ({ contractKey, clientMessage }, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No authorization token found");
    }
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/contract/qdrant-chat-contract/?contract_key=${contractKey}`;
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({ client_message: clientMessage }),
      });

      if (!response.ok) {
        const contentType = response.headers.get("Content-Type");
        if (contentType?.includes("application/json")) {
          const error = await response.json();
          return rejectWithValue(error.message || "Failed to fetch contract chat");
        } else {
          const errorText = await response.text();
          return rejectWithValue(`Unexpected response: ${errorText}`);
        }
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      dispatch(updateStatus('loading'));
      dispatch(updatePartialAnswer('generating')); // Set typing indicator initially
      let accumulatedAnswer = '';
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      // Streaming data from the response body
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.trim().split('\n');
        for (const line of lines) {
          if (!line.trim()) continue;
          try {
            const jsonLine = line.startsWith('data: ') ? line.slice(6).trim() : line.trim();
            const parsedLine = JSON.parse(jsonLine);
            if (parsedLine.ans) {
              accumulatedAnswer += parsedLine.ans;
              dispatch(updatePartialAnswer(accumulatedAnswer)); // Update real-time response
            }
            if (parsedLine.type === 'final') {
              // Extract bot_message_id and run_id when type is "final"
              botMessageId = parsedLine.data.bot_message_id;
              runId = parsedLine.data.run_id;
              generatedQuestions = parsedLine.data.generated_questions;
              dispatch(updateFinalAnswer(accumulatedAnswer));
              dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions, })); // Dispatch botMessageId and runId
              dispatch(updateStatus('succeeded')); // Update status to succeeded once final answer is received
              return accumulatedAnswer; // Final response
            }
          } catch (error) {
            console.error('Error parsing line:', line, error);
          }
        }
      }
      return accumulatedAnswer; // Return accumulated answer if no "final" field
    } catch (error) {
      console.error('Error during PDF chat:', error);
      return rejectWithValue(error.message || 'An error occurred while fetching the PDF chat response');
    }
  }
);

const chatContractSlice = createSlice({
  name: "chatContract",
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
    error: null,
    botMessageId: null, // Store bot_message_id
    runId: null,
    generatedQuestions: null, // Store run_id
  },
  reducers: {
    resetChatContractState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null; // Reset bot_message_id
      state.runId = null;
      state.generatedQuestions = null; // Reset run_id
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId , generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatContract.fulfilled, (state, action) => {
        state.messages.push({ answer: action.payload, botMessageId: state.botMessageId, runId: state.runId });
        state.status = 'succeeded';
      })
      .addCase(fetchChatContract.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});

export const { resetChatContractState, updatePartialAnswer, updateFinalAnswer, updateBotMessageInfo, updateStatus } = chatContractSlice.actions;
export default chatContractSlice.reducer;
