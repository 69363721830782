import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./contractDetails.css";
import bell from "../../Images/ai-bell.png";
import search from "../../Images/search-big.jpg";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import Popup from "./Popup";

const ContractDetails = ({ isOpen }) => {
  const [contractData, setContractData] = useState(null);
  const [contractUrl, setContractUrl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [popupState, setPopupState] = useState({});
  const [isKeyDatesOpen, setIsKeyDatesOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false);
  const [milestoneContent, setMilestoneContent] = useState(""); // Holds milestone details
  const [editNote, setEditNode] = useState("");
  const [editingSection, setEditingSection] = useState(null);
  const [activePopup, setActivePopup] = useState(null); // Tracks which popup is open and its position
  // null means no section is in edit mode
  const [isSaving, setIsSaving] = useState(false); // State to track saving
  const [activeEditField, setActiveEditField] = useState(null); // Track active edit field
  const editRef = useRef(null); // Ref to detect outside clicks

  const [edit, setEdit] = useState(false);
  const [editedData, setEditedData] = useState({});

  const [openSections, setOpenSections] = useState({
    termsAndConditions: false,
    financialTerms: false,
    legalClauses: false,
  });
  const [openClauses, setOpenClauses] = useState({
    termination: false,
    confidentiality: false,
    governing: false,
  });
  const location = useLocation();
  const contractKey = location.state?.contractKey;
  const navigate = useNavigate();
  const [userNote, setUserNote] = useState("");

  const handleViewMoreClick = () => {
    setIsPopupOpen(true);
  };

  const [isMilestonePopupOpen, setIsMilestonePopupOpen] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState({});
  // const handleInputChange = (field, value) => {
  //   setEditedData((prevData) => ({
  //     ...prevData,
  //     [field]: value, // Handle keys with spaces
  //   }));
  // };

  const handleMilestoneViewMore = (milestone, event) => {
    event.stopPropagation();
    const buttonRect = event.target.getBoundingClientRect();
    const popupHeight = 200; // Approximate height of the popup
    const popupWidth = 300; // Approximate width of the popup

    const moveUpBy = 20; // Adjust this value to move the popup upwards

    const top =
      buttonRect.bottom + popupHeight > window.innerHeight
        ? buttonRect.top - popupHeight - moveUpBy // Popup above with extra upward adjustment
        : buttonRect.bottom + moveUpBy; // Default below button but slightly up

    const left = Math.min(
      window.innerWidth - popupWidth, // Prevent overflow on the right
      buttonRect.left // Align with the left of the button
    );

    setActivePopup({
      milestone,
      position: { top: top + window.scrollY, left: left + window.scrollX },
    });
  };

  const closeMilestonePopup = () => {
    setActivePopup(null); // Close the active popup
  };

  const handleEditClick = (field) => {
    setActiveEditField(field); // Set the active field
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const toggleSection = (section) => {
    setOpenSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const toggleClause = (clause) => {
    setOpenClauses((prevClauses) => ({
      ...prevClauses,
      [clause]: !prevClauses[clause],
    }));
  };

  useEffect(() => {
    const fetchContractDetails = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Session expired, please log in.");
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/extract-contract-info/`,
          { contract_key: contractKey },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        console.log("API Response for contract details:", response.data);
        if (response.status === 200) {
          setContractData(response.data.contract_data);
          setEditedData(response.data.contract_data);
          setUserNote(response.data.contract_data?.user_note || "");
        }
      } catch (error) {
        console.log("Fetch error:", error);
        alert(
          error.response?.data.msg ||
            "An error occurred while fetching contract details."
        );
      }
    };
    if (contractKey) fetchContractDetails();
  }, [contractKey, edit]);

  const viewContract = async () => {
    console.log("View Contract button clicked");

    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/view-contract/${contractKey}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      console.log("API Response for view contract:", response.data);
      if (response.status === 200 && response.data.contract_url) {
        setContractUrl(response.data.contract_url);
        setIsPopupOpen(true);
      } else {
        alert(response.data.msg || "Failed to retrieve contract.");
      }
    } catch (error) {
      console.log("Error fetching contract URL:", error);
      alert(
        error.response?.data.msg ||
          "An unexpected error occurred while retrieving the contract."
      );
    }
  };
  const saveNote = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }
    setIsSaving(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-details/${contractKey}/`,
        {
          edited_data: editedData || {}, // Ensure valid edited_data is sent
          note: userNote, // Note to be updated
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Note saved successfully!", response.data);
        setIsSaving(false);
        setContractData(response.data.data); // Update contract data
        setUserNote(response.data.data?.user_note || ""); // Sync local state
      } else {
        console.error("Unexpected response:", response.data);
        console.log(response.data.msg || "Failed to save the note.");
        setIsSaving(false);
      }
    } catch (error) {
      console.error(
        "Error saving note:",
        error.response?.data || error.message
      );
      console.log(
        error.response?.data?.msg || "An error occurred while saving the note."
      );
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editRef.current && !editRef.current.contains(event.target)) {
        if (activeEditField) {
          handleSaveOnBlur(activeEditField); // Save the currently active field
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [activeEditField, editedData]);

  const handleInputChange = (field, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleEditContract = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-details/${contractKey}/`,
        {
          edited_data: {
            ...editedData, // Include all the contract fields you want to update
          },
          note: userNote, // Include the updated note
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // alert(response.data.msg || "Contract updated successfully!");
        setIsEditModalOpen(false); // Close modal after success
        setContractData(response.data.data); // Update the local contract data
        setUserNote(response.data.contract_data?.user_note || "");
      }
    } catch (error) {
      // alert(
      //   error.response?.data.msg ||
      //     "An error occurred while updating the contract details."
      // );
    }
  };
  console.log("editedData", editedData);

  const handleEdit = () => {
    if (edit === true) {
      // Make sure editedData is up-to-date when saving
      handleEditContract(); // This will send the updated editedData
      setEdit(false);
    } else {
      setEdit(true); // Switch to edit mode
    }
  };

  const handleSaveOnBlur = (field) => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Session expired, please log in.");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/edit-contract-details/${contractKey}/`,
        {
          edited_data: { [field]: editedData[field] },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setContractData((prevData) => ({
            ...prevData,
            [field]: editedData[field],
          }));
          setActiveEditField(null); // Reset the active edit field
          setEdit(false)
        }
      })
      .catch((error) => {
        console.error(
          `Error saving field "${field}":`,
          error.response?.data?.msg || error.message
        );
      });
  };

  const cardRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        // Trigger save for each editable field
        if (edit) {
          handleSaveOnBlur("Client name");
          handleSaveOnBlur("Client Contact Person name");
          handleSaveOnBlur("Client address");
          handleSaveOnBlur("Client Contact Person telephone");
          handleSaveOnBlur("Client Contact Person email");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [edit, handleSaveOnBlur]);

  return (
    <div
      ref={editRef}
      className={`contract-details-container ${
        isOpen ? "navbar-open" : "navbar-closed"
      }`}
    >
      <div className="full_Container">
        <div className="header-container">
          <Header
            icon="/arrow-back.svg"
            name="Go to Contracts"
            // bell={bell}
            // search={search}
            askCoPiolt={true}
            contractKey={contractKey}
          />
        </div>
        <div className="contract-details">
          {contractData ? (
            <>
              <header className="header">
                <div className="header-content">
                  <div className="contract-info">
                    <img
                      src="/hicon.svg"
                      alt="icon"
                      className="contract-icon"
                    />
                    <div>
                      <h1 className="contract-title">
                        {contractData.contract_title || "N/A"}
                      </h1>
                      <p className="contract-id">
                        Contract ID:{" "}
                        <span>{contractData.contract_id_number || "N/A"}</span>
                      </p>
                      <p className="contract-type">
                        Contract type:{" "}
                        <span>{contractData.contract_type || "N/A"}</span>
                      </p>
                    </div>
                  </div>
                  <button
                    className="view-contract-button-contract"
                    onClick={viewContract}
                  >
                    <span className="button-icon">
                      <img src="/eye.svg" alt="" />
                    </span>{" "}
                    View Contract
                  </button>
                </div>
              </header>

              {contractUrl && isPopupOpen && (
                <div className="contract-modal-overlay" onClick={closePopup}>
                  <div
                    className="contract-modal-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      className="contract-close-button"
                      onClick={closePopup}
                    >
                      &times;
                    </button>
                    <iframe
                      src={contractUrl}
                      title="Contract Document"
                      className="contract-iframe"
                    ></iframe>
                  </div>
                </div>
              )}

              <h4 className="customer-header">
                <span role="img" aria-label="icon">
                  <img src="/custDetails.svg" alt="" />
                </span>{" "}
                Customer Details
              </h4>
              <section className="customer-details">
                <div className="customer-cards">
                  <div className="customer-card">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start", // Align items at the top
                        position: "relative",
                        alignItems: "center",
                        width: "100%",
                        gap: "10px", // Add space between the title and the name
                      }}
                    >
                      <span
                        className="party-label"
                        style={{
                          fontWeight: "bold",
                          flexShrink: 0, // Prevent the title from shrinking
                        }}
                      >
                        Customer Party A:
                      </span>
                      <div
                        style={{
                          flexGrow: 1, // Allow the name/input to take available space
                          maxWidth: "calc(100% - 40px)", // Leave space for the edit icon
                          wordWrap: "break-word", // Ensure long text wraps
                        }}
                      >
                        {edit ? (
                          <input
                            style={{
                              width: "267px", // Set width
                              height: "27px", // Set height
                              borderRadius: "6px", // Rounded corners
                              border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                              background: "#FEF9F7", // Light background color
                              outline: "none", // Removes focus outline
                              fontSize: "15px", // Font size
                              fontFamily: "Outfit, sans-serif", // Font family
                              fontStyle: "normal", // Font style
                              fontWeight: "500", // Font weight
                              lineHeight: "normal", // Line height
                              color: "#73607B", // Text color
                              paddingBottom: "7px", // Padding
                            }}
                            type="text"
                            value={
                              editedData["Client name"] !== undefined
                                ? editedData["Client name"]
                                : contractData["contract_owner_name"] || ""
                            }
                            onChange={(e) =>
                              handleInputChange("Client name", e.target.value)
                            }
                            onBlur={() => handleSaveOnBlur("Client name")}
                            onFocus={() => handleEditClick("Client name")}
                          />
                        ) : (
                          <strong
                            className="cust-details-customer-a"
                            style={{
                              display: "block", // Correctly set as a style
                              wordWrap: "break-word", // Break long words
                              overflowWrap: "break-word", // Break long words
                            }}
                          >
                            {contractData.contract_owner_name || "N/A"}
                          </strong>
                        )}
                      </div>
                      {!edit && <img
                        src="/edit-icon.svg" // Replace with the actual path to your icon
                        alt="Edit"
                        className="edit-icon"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                          position: "absolute", // Position the icon absolutely
                          right: "10px", // Stick the icon to the right edge
                          top: "50%", // Center the icon vertically
                          transform: "translateY(-50%)", // Adjust for proper centering
                        }}
                        onClick={handleEdit}
                        // onClick={() => handleEditCustomerPartyA()} // Attach your handler here
                      />}
                    </div>
                    <div className="signatory">
                      <strong>Customer Signatory:</strong>{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "267px", // Set width
                            height: "27px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "8px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Client Contact Person name"] !==
                            undefined
                              ? editedData["Client Contact Person name"]
                              : contractData["contract_owner_signatory_name"] ||
                                ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Client Contact Person name",
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            handleSaveOnBlur("Client Contact Person name")
                          }
                        />
                      ) : (
                        <span className="cust-details">
                          {contractData.contract_owner_signatory_name || "N/A"}
                        </span>
                      )}
                    </div>
                    <p className="address">
                      <strong>Address:</strong>{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "312px", // Set width
                            height: "65px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Client address"] !== undefined
                              ? editedData["Client address"]
                              : contractData["contract_owner_address"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Client address", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Client address")}
                        />
                      ) : (
                        <span className="cust-details">
                          {contractData.contract_owner_address || "N/A"}
                        </span>
                      )}
                    </p>
                    <div className="contact-info">
                      <span role="img" aria-label="phone">
                        <img src="/call.svg" alt="" />
                      </span>
                      {edit ? (
                        <input
                          style={{
                            width: "267px", // Set width
                            height: "27px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Client Contact Person telephone"] !==
                            undefined
                              ? editedData["Client Contact Person telephone"]
                              : contractData["contract_owner_telephone"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Client Contact Person telephone",
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            handleSaveOnBlur("Client Contact Person telephone")
                          }
                        />
                      ) : (
                        <span className="phone-data">
                          {contractData.contract_owner_telephone || "N/A"}
                        </span>
                      )}
                    </div>
                    <div className="contact-info">
                      <span role="img" aria-label="email">
                        <img src="/message.svg" alt="" className="email-img" />
                      </span>
                      {edit ? (
                        <input
                          style={{
                            width: "267px", // Set width
                            height: "27px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Client Contact Person email"] !==
                            undefined
                              ? editedData["Client Contact Person email"]
                              : contractData["contract_owner_email"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Client Contact Person email",
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            handleSaveOnBlur("Client Contact Person email")
                          }
                        />
                      ) : (
                        <span className="email-data">
                          {contractData.contract_owner_email || "N/A"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="customer-card">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start", // Align items at the top
                        position: "relative", // Set relative positioning for the parent container
                        width: "100%",
                        alignItems: "center",
                        gap: "10px",
                        // Add space between the title and the name
                      }}
                    >
                      <span
                        className="party-label"
                        style={{
                          fontWeight: "bold",
                          flexShrink: 0, // Prevent the title from shrinking
                        }}
                      >
                        Customer Party B:
                      </span>
                      <div
                        style={{
                          flexGrow: 1, // Allow the name/input to take available space
                          maxWidth: "calc(100% - 40px)", // Leave space for the edit icon
                          wordWrap: "break-word", // Ensure long text wraps
                        }}
                      >
                        {edit ? (
                          <input
                            style={{
                              width: "267px", // Set width
                              height: "27px", // Set height
                              borderRadius: "6px", // Rounded corners
                              border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                              background: "#FEF9F7", // Light background color
                              outline: "none", // Removes focus outline
                              fontSize: "15px", // Font size
                              fontFamily: "Outfit, sans-serif", // Font family
                              fontStyle: "normal", // Font style
                              fontWeight: "500", // Font weight
                              lineHeight: "normal", // Line height
                              color: "#73607B", // Text color
                              paddingBottom: "10px", // Padding
                            }}
                            type="text"
                            value={
                              editedData["Company Name"] !== undefined
                                ? editedData["Company Name"]
                                : contractData["customer_name"] || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Company Name", // Use a unique key for Customer B
                                e.target.value
                              )
                            }
                            onBlur={() => handleSaveOnBlur("Company Name")}
                          />
                        ) : (
                          <strong
                            className="cust-details-customer"
                            style={{
                              display: "block", // Correctly set as a style
                              wordWrap: "break-word", // Break long words
                              overflowWrap: "break-word", // Break long words
                            }}
                          >
                            {contractData.customer_name || "N/A"}{" "}
                            {/* Display Customer B data */}
                          </strong>
                        )}
                      </div>
                      {!edit && <img
                        src="/edit-icon.svg" // Replace with the actual path to your icon
                        alt="Edit"
                        className="edit-icon"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                          position: "absolute", // Position the icon absolutely
                          right: "10px", // Stick the icon to the right edge
                          top: "50%", // Center the icon vertically
                          transform: "translateY(-50%)", // Adjust for proper centering
                        }}
                        onClick={handleEdit}
                        // onClick={() => handleEditCustomerPartyA()} // Attach your handler here
                      />}
                    </div>

                    <p className="signatory">
                      <strong>Customer Signatory:</strong>{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "267px", // Set width
                            height: "27px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Company Contact Person Name"] !==
                            undefined
                              ? editedData["Company Contact Person Name"]
                              : contractData["customer_signatory_name"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Company Contact Person Name",
                              e.target.value
                            )
                          }
                          onBlur={() =>
                            handleSaveOnBlur("Company Contact Person Name")
                          }
                        />
                      ) : (
                        <span className="cust-details">
                          {contractData.customer_signatory_name || "N/A"}
                        </span>
                      )}
                    </p>

                    <p className="address">
                      <strong>Address:</strong>{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "312px", // Set width
                            height: "65px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Company Address"] !== undefined
                              ? editedData["Company Address"]
                              : contractData["customer_address"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Company Address", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Company Address")}
                        />
                      ) : (
                        <span className="cust-details">
                          {contractData.customer_address || "N/A"}
                        </span>
                      )}
                    </p>

                    <div className="contact-info">
                      <span role="img" aria-label="phone">
                        <img src="/call.svg" alt="" />
                      </span>
                      {edit ? (
                        <input
                          style={{
                            width: "267px", // Set width
                            height: "27px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Company telephone"] !== undefined
                              ? editedData["Company telephone"]
                              : contractData["customer_telephone"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              "Company telephone",
                              e.target.value
                            )
                          }
                          onBlur={() => handleSaveOnBlur("Company telephone")}
                        />
                      ) : (
                        <span className="phone-data">
                          {contractData.customer_telephone || "N/A"}
                        </span>
                      )}
                    </div>

                    <div className="contact-info">
                      <span role="img" aria-label="email">
                        <img src="/message.svg" alt="" className="email-img" />
                      </span>
                      {edit ? (
                        <input
                          style={{
                            width: "267px", // Set width
                            height: "27px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "10px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Company email"] !== undefined
                              ? editedData["Company email"]
                              : contractData["customer_email"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Company email", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Company email")}
                        />
                      ) : (
                        <span className="email-data">
                          {contractData.customer_email || "N/A"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section className="key-dates">
                <div
                  className="key-dates-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={() => toggleSection("keyDates")}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img src="/key-dates.svg" alt="Key Dates Icon" />
                    <h3>Key Dates</h3>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    {/* Edit Icon */}
                   {!edit && <img
                      src="/edit-icon.svg" // Replace with the actual path to your edit icon
                      alt="Edit"
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={handleEdit}
                      // onClick={() => handleEditKeyDates()} // Edit handler for Key Dates
                    />}
                    {/* Dropdown Toggle Icon */}
                    <img
                      src={isKeyDatesOpen ? "/up-arrow.svg" : "/down-arrow.svg"} // Path to dropdown and dropup icons
                      alt="Toggle Dropdown"
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={() => setIsKeyDatesOpen((prev) => !prev)} // Toggle dropdown state
                    />
                  </div>
                </div>
                <p
                  style={{
                    color: "var(--text_non_active, #73607B)",
                    fontFamily: "Outfit",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                    marginTop: "-15px",
                  }}
                >
                  View your all Important Key dates.
                </p>

                {/* Dropdown Content */}
                {isKeyDatesOpen && (
                  <div className="date-details">
                    <p className="effective">
                      Effective date:{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "155px", // Set width
                            height: "28px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "7px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Effective Date"] !== undefined
                              ? editedData["Effective Date"]
                              : contractData["effective_date"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Effective Date", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Effective Date")}
                        />
                      ) : (
                        <span className="keyValue">
                          {contractData.effective_date || "N/A"}
                        </span>
                      )}
                    </p>
                    <p className="effective">
                      Execution date:{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "155px", // Set width
                            height: "28px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "7px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Execution Date"] !== undefined
                              ? editedData["Execution Date"]
                              : contractData["execution_date"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Execution Date", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Execution Date")}
                        />
                      ) : (
                        <span className="keyValue">
                          {contractData.execution_date || "N/A"}
                        </span>
                      )}
                    </p>
                    <p className="effective">
                      Payment date 1:{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "155px", // Set width
                            height: "28px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "7px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Payment date 1"] !== undefined
                              ? editedData["Payment date 1"]
                              : contractData["payment_date_1"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Payment date 1", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Payment date 1")}
                        />
                      ) : (
                        <span className="keyValue">
                          {contractData.payment_date_1 || "N/A"}
                        </span>
                      )}
                    </p>
                    <p className="effective">
                      Expiration date:{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "155px", // Set width
                            height: "28px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "7px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Expiration Date"] !== undefined
                              ? editedData["Expiration Date"]
                              : contractData["expiration_date"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Expiration Date", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Expiration Date")}
                        />
                      ) : (
                        <span className="keyValue">
                          {contractData.expiration_date || "N/A"}
                        </span>
                      )}
                    </p>
                    <p className="effective">
                      Renewal date:{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "155px", // Set width
                            height: "28px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "7px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Renewal Date"] !== undefined
                              ? editedData["Renewal Date"]
                              : contractData["renewal_date"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Renewal Date", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Renewal Date")}
                        />
                      ) : (
                        <span className="keyValue">
                          {contractData.renewal_date || "N/A"}
                        </span>
                      )}
                    </p>
                    <p className="effective">
                      Payment date 2:{" "}
                      {edit ? (
                        <input
                          style={{
                            width: "155px", // Set width
                            height: "28px", // Set height
                            borderRadius: "6px", // Rounded corners
                            border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                            background: "#FEF9F7", // Light background color
                            outline: "none", // Removes focus outline
                            fontSize: "15px", // Font size
                            fontFamily: "Outfit, sans-serif", // Font family
                            fontStyle: "normal", // Font style
                            fontWeight: "500", // Font weight
                            lineHeight: "normal", // Line height
                            color: "#73607B", // Text color
                            paddingBottom: "7px", // Padding
                          }}
                          type="text"
                          value={
                            editedData["Payment date 2"] !== undefined
                              ? editedData["Payment date 2"]
                              : contractData["payment_date_2"] || ""
                          }
                          onChange={(e) =>
                            handleInputChange("Payment date 2", e.target.value)
                          }
                          onBlur={() => handleSaveOnBlur("Payment date 2")}
                        />
                      ) : (
                        <span className="keyValue">
                          {contractData.payment_date_2 || "N/A"}
                        </span>
                      )}
                    </p>
                  </div>
                )}

                <div className="milestone-section">
                  <div
                    className="milestone-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => toggleSection("milestones")}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <h4
                        style={{
                          color: "var(--text_non_active, #73607B)",
                          fontFamily: "Outfit",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        Milestone Dates
                      </h4>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <img
                        src={
                          isMilestoneOpen ? "/up-arrow.svg" : "/down-arrow.svg"
                        }
                        alt="Toggle Dropdown"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                          marginRight: "990px",
                        }}
                        onClick={() => setIsMilestoneOpen((prev) => !prev)} // Toggle state
                      />
                    </div>
                  </div>

                  {isMilestoneOpen && (
                    <div className="milestone-cards">
                      {/* Dynamically render milestones */}
                      {[1, 2, 3].map((num) => {
                        const dateKey = `milestone_date_${num}`;
                        const descKey = `milestone_date_${num}_desc`;
                        if (contractData[dateKey]) {
                          return (
                            <div className="milestone-card" key={num}>
                              <div className="milestone-header">
                                <div className="milestone-date">
                                  <span
                                    className="calendar-icon"
                                    role="img"
                                    aria-label="calendar"
                                  >
                                    <img
                                      src="/calendarmilestone.svg"
                                      alt="Calendar Icon"
                                    />
                                  </span>
                                  <strong className="Strong-mile-date">
                                    {contractData[dateKey] || "N/A"}
                                  </strong>
                                </div>
                                <span
                                  className="view-more"
                                  onClick={(event) =>
                                    handleMilestoneViewMore(
                                      {
                                        date: contractData[dateKey],
                                        description:
                                          contractData[descKey] ||
                                          "No description available",
                                      },
                                      event
                                    )
                                  }
                                >
                                  View More
                                </span>
                              </div>
                              <p className="milestone-description">
                                {contractData[descKey] ||
                                  "No description available"}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      })}

                      {/* Popup for milestones */}
                      {activePopup && (
                        <div
                          className="popup-attached"
                          style={{
                            position: "fixed",
                            top: "40%", // Vertically center the popup
                            left: "58%",
                            zIndex: 1000,
                            backgroundColor: "white",
                            padding: "17px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="popup-header">
                            <button className="milestone-button">
                              Milestone Date
                            </button>
                            <img
                              src="/mileStone-icon.svg"
                              alt=""
                              className="mileStone-icon"
                            />
                            <h3 className="popup-date">
                              {activePopup.milestone.date}
                            </h3>
                            <button
                              className="popup-close"
                              onClick={closeMilestonePopup}
                            >
                              x
                            </button>
                          </div>
                          <div className="popup-body">
                            <p className="popup-description">
                              {activePopup.milestone.description}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </section>

              <section className="terms-and-conditions-section">
                <div className="accordion-item">
                  <div
                    className="accordion-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    // onClick={() => toggleSection("termsAndConditions")}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img src="/term.svg" alt="" className="icon" />
                      <p className="Terms-condition-title">
                        Terms and Conditions
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      {/* Edit Icon */}
                      {!edit && <img
                        src="/edit-icon.svg" // Replace with the actual path to your edit icon
                        alt="Edit"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                          marginRight: "8px", // Adjust spacing
                        }}
                        onClick={handleEdit}
                        // onClick={() => handleEditTermsAndConditions()} // Add handler for editing
                      />}
                      {/* Dropdown/Dropup Toggle Icon */}
                      <img
                        src={
                          openSections.termsAndConditions
                            ? "/up-arrow.svg"
                            : "/down-arrow.svg"
                        } // Use appropriate paths
                        alt={
                          openSections.termsAndConditions
                            ? "Collapse"
                            : "Expand"
                        }
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                        }}
                        onClick={() => toggleSection("termsAndConditions")} // Toggle dropdown state
                      />
                    </div>
                  </div>

                  {/* Dropdown Content */}
                  {openSections.termsAndConditions && (
                    <div className="accordion-content">
                      <div className="terms-and-conditions-content">
                        <div className="contract-duration">
                          <p className="label-terms">Contract Duration</p>
                          {edit ? (
                            <input
                              style={{
                                width: "287px", // Set width
                                height: "28px", // Set height
                                borderRadius: "6px", // Rounded corners
                                border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                                background: "#FEF9F7", // Light background color
                                outline: "none", // Removes focus outline
                                fontSize: "15px", // Font size
                                fontFamily: "Outfit, sans-serif", // Font family
                                fontStyle: "normal", // Font style
                                fontWeight: "500", // Font weight
                                lineHeight: "normal", // Line height
                                color: "#73607B", // Text color
                                paddingBottom: "7px", // Padding
                              }}
                              type="text"
                              value={
                                editedData["Contract duration with dates"] !==
                                undefined
                                  ? editedData["Contract duration with dates"]
                                  : contractData["contract_duration"] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Contract duration with dates",
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                handleSaveOnBlur("Contract duration with dates")
                              }
                            />
                          ) : (
                            <p className="value">
                              {contractData.contract_duration || "N/A"}
                            </p>
                          )}
                        </div>
                        <div className="payment-terms">
                          <p className="label-terms">Payment Terms</p>
                          {edit ? (
                            <input
                              style={{
                                width: "287px", // Set width
                                height: "28px", // Set height
                                borderRadius: "6px", // Rounded corners
                                border: "0.5px solid rgba(0, 0, 0, 0.10)", // Border styling
                                background: "#FEF9F7", // Light background color
                                outline: "none", // Removes focus outline
                                fontSize: "15px", // Font size
                                fontFamily: "Outfit, sans-serif", // Font family
                                fontStyle: "normal", // Font style
                                fontWeight: "500", // Font weight
                                lineHeight: "normal", // Line height
                                color: "#73607B", // Text color
                                paddingBottom: "7px", // Padding
                              }}
                              type="text"
                              value={
                                editedData["Payment terms"] !== undefined
                                  ? editedData["Payment terms"]
                                  : contractData["payment_terms"] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Payment terms",
                                  e.target.value
                                )
                              }
                              onBlur={() => handleSaveOnBlur("Payment terms")}
                            />
                          ) : (
                            <p className="value">
                              {contractData.payment_terms || "N/A"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className="clause-header-contract"
                        onClick={() => toggleClause("termination")}
                      >
                        <p className="termination-title">Termination Clause</p>
                        <img
                          src={
                            openClauses.termination
                              ? "/up-arrow.svg"
                              : "/down-arrow.svg"
                          }
                          alt={openClauses.termination ? "Collapse" : "Expand"}
                          className="toggle-icon"
                        />
                      </div>
                      {openClauses.termination && (
                        <div className="clause-content">
                          {edit ? (
                            <input
                              style={{
                                width: "287px",
                                height: "28px",
                                borderRadius: "6px",
                                border: "0.5px solid rgba(0, 0, 0, 0.10)",
                                background: "#FEF9F7",
                                outline: "none",
                                fontSize: "15px",
                                fontFamily: "Outfit, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                color: "#73607B",
                                paddingBottom: "7px",
                              }}
                              type="text"
                              value={
                                editedData["Termination Clause"] !== undefined
                                  ? editedData["Termination Clause"]
                                  : contractData["termination_clause"] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Termination Clause",
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                handleSaveOnBlur("Termination Clause")
                              }
                            />
                          ) : (
                            <p className="termination-clause-desc">
                              {contractData.termination_clause || "N/A"}
                            </p>
                          )}
                        </div>
                      )}

                      <div
                        className="clause-header"
                        onClick={() => toggleClause("confidentiality")}
                      >
                        <p>Confidentiality Clause</p>
                        <img
                          src={
                            openClauses.confidentiality
                              ? "/up-arrow.svg"
                              : "/down-arrow.svg"
                          }
                          alt={
                            openClauses.confidentiality ? "Collapse" : "Expand"
                          }
                          className="toggle-icon"
                        />
                      </div>
                      {openClauses.confidentiality && (
                        <div className="clause-content">
                          {edit ? (
                            <input
                              style={{
                                width: "287px",
                                height: "28px",
                                borderRadius: "6px",
                                border: "0.5px solid rgba(0, 0, 0, 0.10)",
                                background: "#FEF9F7",
                                outline: "none",
                                fontSize: "15px",
                                fontFamily: "Outfit, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                color: "#73607B",
                                paddingBottom: "7px",
                              }}
                              type="text"
                              value={
                                editedData["Confidentiality Clause"] !==
                                undefined
                                  ? editedData["Confidentiality Clause"]
                                  : contractData["confidentiality_clause"] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Confidentiality Clause",
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                handleSaveOnBlur("Confidentiality Clause")
                              }
                            />
                          ) : (
                            <p className="termination-clause-desc">
                              {contractData.confidentiality_clause || "N/A"}
                            </p>
                          )}
                        </div>
                      )}

                      <div
                        className="clause-header"
                        onClick={() => toggleClause("governing")}
                      >
                        <p>Governing Law</p>
                        <img
                          src={
                            openClauses.governing
                              ? "/up-arrow.svg"
                              : "/down-arrow.svg"
                          }
                          alt={openClauses.governing ? "Collapse" : "Expand"}
                          className="toggle-icon"
                        />
                      </div>
                      {openClauses.governing && (
                        <div className="clause-content">
                          {edit ? (
                            <input
                              style={{
                                width: "287px",
                                height: "28px",
                                borderRadius: "6px",
                                border: "0.5px solid rgba(0, 0, 0, 0.10)",
                                background: "#FEF9F7",
                                outline: "none",
                                fontSize: "15px",
                                fontFamily: "Outfit, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "normal",
                                color: "#73607B",
                                paddingBottom: "7px",
                              }}
                              type="text"
                              value={
                                editedData["Governing law"] !== undefined
                                  ? editedData["Governing law"]
                                  : contractData["governing_law"] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Governing law",
                                  e.target.value
                                )
                              }
                              onBlur={() => handleSaveOnBlur("Governing law")}
                            />
                          ) : (
                            <p className="termination-clause-desc">
                              {contractData.governing_law || "N/A"}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </section>

              <section className="financial-terms-section">
                <div className="accordion-item">
                  <div
                    className="accordion-header"
                    onClick={() => toggleSection("financialTerms")}
                  >
                    <p className="financial">
                      <span>
                        <img src="/financial.svg" alt="Financial Icon" />
                      </span>{" "}
                      Financial Terms
                    </p>
                    <img
                      src={
                        openSections.termsAndConditions
                          ? "/up-arrow.svg"
                          : "/down-arrow.svg"
                      }
                      alt={
                        openSections.termsAndConditions ? "Collapse" : "Expand"
                      }
                      className="toggle-icon-fin"
                    />
                  </div>
                  {openSections.financialTerms && (
                    <div className="accordion-content">
                      {contractData.financial_terms ? (
                        <table className="financial-terms-table">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contractData.financial_terms
                              .split("\n") // Split the string into an array of lines
                              .map((line, index) => {
                                const [description, value] = line.split(":"); // Separate description and value
                                return (
                                  <tr key={index}>
                                    <td>{description.trim()}</td>
                                    <td>{value ? value.trim() : "N/A"}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <p>No Financial Terms available.</p>
                      )}
                    </div>
                  )}
                </div>
              </section>

              <section className="legal-clauses-section">
                <div className="accordion-item">
                  <div
                    className="accordion-header"
                    onClick={() => toggleSection("legalClauses")}
                  >
                    <p className="financial">
                      <span>
                        <img src="/legal.svg" alt="Legal Icon" />
                      </span>{" "}
                      Legal Clauses
                    </p>
                    <img
                      src={
                        openSections.termsAndConditions
                          ? "/up-arrow.svg"
                          : "/down-arrow.svg"
                      }
                      alt={
                        openSections.termsAndConditions ? "Collapse" : "Expand"
                      }
                      className="toggle-icon-legal"
                    />
                  </div>
                  {openSections.legalClauses && (
                    <div className="accordion-content legal-clauses-container">
                      {contractData.legal_clauses ? (
                        <div className="legal-clauses-list">
                          {contractData.legal_clauses
                            .split("\n") // Split clauses into lines
                            .map((clause, index) => (
                              <p key={index} className="legal-clause-item">
                                {clause.trim()} {/* Render each clause */}
                              </p>
                            ))}
                        </div>
                      ) : (
                        <p>No Legal Clauses available.</p>
                      )}
                    </div>
                  )}
                </div>
              </section>

              <section className="notes-section">
                <div className="notes-item">
                  <div
                    className="notes-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p className="notes" style={{ margin: 0 }}>
                      <span>
                        <img src="/notes.svg" alt="" />
                      </span>{" "}
                      Notes
                    </p>
                  </div>
                  <textarea
                    placeholder="Enter your comments here..."
                    className="notes-input"
                    style={{
                      width: "95%",
                      marginTop: "10px",
                      padding: "10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    }}
                    value={userNote}
                    onChange={(e) => setUserNote(e.target.value)} // Update state on change
                  ></textarea>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end", // Aligns content to the right
                      width: "100%", // Ensures the container takes full width
                    }}
                  >
                    <button
                      className="save-button"
                      onClick={saveNote}
                      style={{
                        padding: "6px 12px",
                        backgroundColor: "#C95EBE",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      disabled={isSaving}
                    >
                      {isSaving ? "Saved" : "Save Notes"}
                    </button>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <p>Loading contract details...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
