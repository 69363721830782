import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import UploadDocumentModal from "../Contract/UploadDocumentModal"; // Adjust the path to the actual location of UploadModal
import FilterModalForFolder from "./FilterModalForFolder";
import "./FolderContent.css";
import UploadModal from "./UploadModal"; 
import "./contract_data.css";
// import './uploadModal.css'

const FolderContents = () => {
  const location = useLocation();
  const [isPopupVisible, setIsPopupVisible] = useState(false); // Track popup visibility
  const { folderName } = useParams();
  const [files, setFiles] = useState([]); 
  const [folders, setFolders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [renameInput, setRenameInput] = useState("");
  const [deletingDocId, setDeletingDocId] = useState(null);
  const navigate = useNavigate();

  const [isRenameFolderModalOpen, setIsRenameFolderModalOpen] = useState(false);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [renameFolderInput, setRenameFolderInput] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showBulkActions, setShowBulkActions] = useState(false); // To show or hide bulk actions bar
  const [activeActionMenuFolder, setActiveActionMenuFolder] = useState(null);
  const dropdownRefs = useRef([]); // Array of refs for each dropdown

  const { pdfUrl, pdfKey } = location.state || {};
  const token = localStorage.getItem("token");

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null); // Store the document that is being renamed or deleted

  const folderId = new URLSearchParams(location.search).get("folderId");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  

  const actionMenuRef = useRef(null);
  const totalPages = Math.ceil((files.documents?.length || 0) / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const showPopup = (type, message) => {
    alert(`${type}: ${message}`); // You can replace this with a custom notification UI
  };

  const toggleFolderActionMenu = (folder) => {
    // Toggle visibility of the action menu for the clicked folder
    setActiveActionMenuFolder((prevFolder) =>
      prevFolder === folder ? null : folder
    );
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const [filters, setFilters] = useState({
    folder: true,
    normalDocument: true,
    contract: false,
    invoices: false,
  });

  const handleCancel = () => {
    // Clear selected contracts

    // Close any active modals or popups
    setIsModalOpen(false); // Close the upload modal
    setIsFilterModalOpen(false); // Close the filter modal
    // Close the popup if visible

    // Reset any additional state as needed
    // Close delete confirmation popup
  };

  const handleCheckboxChange = (fileId) => {
    setSelectedFiles((prevSelected) => {
      const updatedSelected = prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId) // Unselect
        : [...prevSelected, fileId]; // Select

      // Update showBulkActions based on the selected files
      setShowBulkActions(updatedSelected.length > 0);

      return updatedSelected;
    });
  };

  const handleSelectAll = () => {
    if (!files.documents || files.documents.length === 0) {
      setSelectedFiles([]); // Deselect all if there are no files
      setShowBulkActions(false); // Hide bulk actions
      return;
    }
  
    if (selectedFiles.length === files.documents.length) {
      setSelectedFiles([]); // Deselect all if already all are selected
      setShowBulkActions(false); // Hide bulk actions
    } else {
      const allFileIds = files.documents.map((file) => file.id);
      setSelectedFiles(allFileIds); // Select all files
      setShowBulkActions(true); // Show bulk actions when files are selected
    }
  };
  

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    // Update your file/folder filtering logic here based on `newFilters`
  };

  const filteredDocuments = files.documents
    ? files.documents.filter(
        (file) => file.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
      )
    : [];

  const RenameModal = ({ isOpen, onClose, onRename, fileName }) => {
    const [newName, setNewName] = useState(fileName);

    const handleSubmit = () => {
      if (newName.trim() !== "") {
        onRename(newName); // Call the rename handler
        onClose(); // Close the modal
      }
    };

    if (!isOpen) return null;

    return (
      <div className="rename-modal-overlay">
        <div className="rename-modal">
          <h2>Rename Document</h2>
          <p>Create a Custom Name for your Documents</p>
          <h3>Title</h3>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Enter new name"
          />
          <div className="rename-modal-actions">
            <button onClick={onClose}>Cancel</button>
            <button onClick={handleSubmit}>Save</button>
          </div>
        </div>
      </div>
    );
  };

  const DeleteModal = ({ isOpen, onClose, onDelete, fileName }) => {
    if (!isOpen) return null;

    return (
      <div className="document-popup-overlay">
        <div className="document-popup-box-delete">
          <h2>Confirm Deletion</h2>
          <p under-folder-delete-popup>
            Are you sure you want to delete this PDF? This action cannot be
            undone.
          </p>
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <button
              onClick={onDelete} // Call confirmDelete for simple deletion
              className="document-popup-close-delete"
            >
              Yes, Delete
            </button>
            <button
              onClick={() => onClose(null)} // Close the popup
              className="document-popup-close-button ml-[-22px]"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleRenameFolder = async (newName) => {
    if (!newName) {
      showPopup("Error", "Folder name is required.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/rename-folder/${currentFolder.id}/`,
        { new_name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setFolders((prevFolders) =>
          prevFolders.map((folder) =>
            folder.id === currentFolder.id
              ? { ...folder, name: newName }
              : folder
          )
        );
        setIsRenameFolderModalOpen(false); // Close rename modal
        showPopup("Success", "Folder renamed successfully.");
      }
    } catch (error) {
      console.error("Error renaming folder:", error);
      showPopup("Error", "Failed to rename folder. Please try again.");
    }
  };

  // Function to handle folder deletion
  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/delete-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 204) {
        setFolders((prevFolders) =>
          prevFolders.filter((folder) => folder.id !== folderId)
        );
        setIsDeleteFolderModalOpen(false); // Close delete modal
        showPopup("Success", "Folder deleted successfully.");
      } else {
        showPopup("Error", "Failed to delete folder. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      showPopup("Error", "Failed to delete folder. Please try again.");
    }
  };
  const toggleFilterModal = () => {
    setIsFilterModalOpen((prev) => !prev);
  };

  const handleFolderActionClick = (action, folder) => {
    setCurrentFolder(folder); // Set the current folder for the action
    if (action === "rename") {
      setRenameFolderInput(folder.name); // Set folder's name for renaming
      setIsRenameFolderModalOpen(true); // Open the rename modal
    } else if (action === "delete") {
      setIsDeleteFolderModalOpen(true); // Open the delete modal
    }
  };

  const fetchFiles = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/files-in-the-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedDocuments = response.data.documents.map((doc) => ({
          ...doc,
          pdf_url: doc.url || "", // Map `url` to `pdf_url`
        }));
        setFiles({ ...response.data, documents: updatedDocuments });
        setFolders({ folder_name: response.data.folder_name });
      } else {
        console.log("Failed to fetch folder files.");
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [folderId]);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionMenuIndex !== null &&
        dropdownRefs.current[actionMenuIndex] &&
        !dropdownRefs.current[actionMenuIndex].contains(event.target)
      ) {
        setActionMenuIndex(null);
      }
    };
    

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleImageClick = () => {
    setIsPopupVisible(!isPopupVisible); // Toggle popup visibility
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const [actionMenuIndex, setActionMenuIndex] = useState(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionMenuIndex !== null &&
        dropdownRefs.current[actionMenuIndex] &&
        !dropdownRefs.current[actionMenuIndex].contains(event.target)
      ) {
        setActionMenuIndex(null);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [actionMenuIndex]); // Track which menu is open
  const toggleActionMenu = (index) => {
    setActionMenuIndex(actionMenuIndex === index ? null : index);
  };
  
  useEffect(() => {
    if (files.documents?.length >= 5) {
      setIsPopupVisible(true); // Show popup if document limit is reached
    }
  }, [files.documents]);
  
  const paginatedDocuments = filteredDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log("page", filteredDocuments)

  if (loading) return <p>Loading folder contents...</p>;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleBulkDelete = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected for deletion");
      return;
    }
  
    try {
      setLoading(true); // Show a loading indicator during the deletion process
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/bulk-delete-from-folder/${folderId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          data: { document_ids: selectedFiles }, // Send selected file IDs
        }
      );
  
      if (response.status === 200) {
        const { removed_documents, not_found_documents } = response.data;
  
        // Update files to exclude removed documents
        const updatedDocuments = files.documents.filter(
          (doc) => !removed_documents.some((removed) => removed.document_id === doc.id)
        );
        setFiles((prevFiles) => ({ ...prevFiles, documents: updatedDocuments }));
  
        // Remove deleted files from `selectedFiles`
        const updatedSelectedFiles = selectedFiles.filter(
          (id) => !removed_documents.some((removed) => removed.document_id === id)
        );
        setSelectedFiles(updatedSelectedFiles); // Update the selection
  
        if (updatedSelectedFiles.length === 0) {
          setShowBulkActions(false); // Hide bulk actions only if no files are selected
        }
  
        // Optionally log or handle `not_found_documents` if needed
        if (not_found_documents.length > 0) {
          console.error(`Some documents could not be removed:`, not_found_documents);
        }
      } else {
        console.error("Failed to delete documents. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting documents:", error);
    } finally {
      setLoading(false); // Hide loading indicator after deletion process
    }
  };
  
  
  
  const handleBulkExport = async () => {
    if (selectedFiles.length === 0) {
      alert("No files selected for export");
      return;
    }
  
    try {
      setLoading(true); // Show a loading indicator during the export process
      const token = localStorage.getItem("token");
  
      // Fetch the selected file objects from `files.documents`
      const selectedFileObjects = files.documents.filter((file) =>
        selectedFiles.includes(file.id)
      );
  
      const contractKeys = selectedFileObjects
        .filter((file) => file.type === "contract")
        .map((file) => file.key);
  
      const invoiceKeys = selectedFileObjects
        .filter((file) => file.type === "invoice")
        .map((file) => file.key);
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/export-doc/`,
        {
          contract_keys: contractKeys,
          invoice_keys: invoiceKeys,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
  
      // Download the CSV file
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "exported_documents.csv";
      link.click();
  
      // Keep the bulk actions visible
      if (selectedFiles.length > 0) {
        setShowBulkActions(true);
      }
    } catch (error) {
      console.error("Error exporting files:", error.response?.data || error.message);
      alert("An error occurred while exporting files. Please try again.");
    } finally {
      setLoading(false); // Hide loading indicator after export process
    }
  };
  
  
  

  const handleUploadSuccess = () => {
    if (files.documents?.length >= 5) {
      setIsPopupVisible(true); // Show the popup if limit is reached
      return; // Prevent further uploads
    }
  
    fetchFiles(); // Refresh the file list after upload
    handleCloseModal();
  };
  

  const handleRename = async (newName) => {
    if (!currentFile) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/rename-doc/${currentFile.id}/`,
        {
          new_name: newName,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        fetchFiles(); // Refresh the file list
        setIsRenameModalOpen(false); // Close rename modal
      } else {
        console.error("Failed to rename document.");
      }
    } catch (error) {
      console.error("Error renaming document:", error);
    }
  };

  const handleDelete = async () => {
    if (!currentFile) return;

    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/remove-doc-from-folder/${folderId}/${currentFile.id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchFiles(); // Refresh the file list
        setIsDeleteModalOpen(false); // Close delete modal
      } else {
        console.error("Failed to delete document.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };
  

  return (
    <div className="Contents-Full-Container">
      <div>
        <Header icon={"/arrow-back.svg"} name="Go to Documents" />
      </div>
      <div className="folder-content-info">
        <div className="main-content">
          <img
            src="/folder-icon-folder.svg"
            alt="Folder Icon"
            className="folder-icon"
          />
          <h2 className="folder-name-underfolder">
            {folders.folder_name || "N/A"}
          </h2>
         

          {/* Conditionally render the folder action menu */}
          
        </div>

        <div className="action-buttons-content">
          {/* Conditionally render the buttons based on whether any files are selected */}
          {selectedFiles.length === 0 && (
            <>
              <button
                className="ask-copilot-button-content"
                onClick={() => {
                  if (files.documents) {
                    navigate("/Contract/FolderChat", {
                      state: {
                        folderId: folderId,
                        folderName: folders.folder_name || "N/A",
                        pdfList: files.documents.map((doc) => ({
                          pdfKey: doc.id,
                          pdfUrl: doc.url,
                          name: doc.name,
                        })), // Pass an array of PDF data
                      },
                    });
                  }
                }}
              >
                <img src="/askaicontent.svg" alt="Ask Copilot" />
                Ask Copilot
              </button>

              <button
                className="upload-button-content"
                onClick={handleOpenModal}
                disabled={files.documents?.length >= 5}
              >
                <img src="/upload_white.svg" alt="Upload" />
                Upload
              </button>
            </>
          )}
        </div>
      </div>

      <div className="document-search-filter-container">
        <div className="document-search-box">
          <img src="/search.svg" alt="" />
          <input
            type="text"
            placeholder="Search Documents Here"
            className="document-input-space"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
          />
        </div>
        <button
          className="document-filter-button-inside"
          onClick={toggleFilterModal}
        >
          <img src="/filter.svg" alt="Filter Icon" />
        </button>
        {showBulkActions && (
          <div className="bulk-action-bar">
            <button
              onClick={handleBulkExport}
              disabled={selectedFiles.length === 0}
              className="bulk-action-button export-button"
            >
              <img
                src="/export-icon-blue.svg"
                alt="Export"
                className="button-icon"
              />
              <span className="export-spna">Export</span>
            </button>
            <button
              onClick={handleBulkDelete}
              disabled={selectedFiles.length === 0}
              className="bulk-action-button delete-button"
            >
              <img
                src="/delete-icon-red.svg"
                alt="Delete"
                className="button-icon"
              />
              <span className="delete-spna">Delete</span>
            </button>

            <button
              onClick={() => {
                setSelectedFiles([]); // Clear all selected files
                setShowBulkActions(false); // Hide the bulk action bar
              }}
              className="bulk-action-button cancel-button"
            >
              {/* <img src="/cancel-icon.svg" alt="Cancel" className="button-icon" /> */}
              <span>X</span>
            </button>
          </div>
        )}
      </div>

      <div className="container">
  <table className="contract-table">
    <thead>
      <tr>
        <th>
          <input
            type="checkbox"
            className="document-custom-checkbox"
            checked={files.documents?.length > 0 && selectedFiles?.length === files?.documents?.length} // Select all if all are selected
            onChange={handleSelectAll} // Handle select/deselect all
            disabled={!files.documents?.length} // Disable if no documents
          />
        </th>
        <th>File Name</th>
        <th>File Type</th>
        <th>Size</th>
        <th>Uploaded Date</th>
        <th></th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {files.documents && files.documents.length > 0 ? (
        paginatedDocuments.map((file, index) => (
          <tr key={file.id}>
            <td>
              <input
                type="checkbox"
                className="document-custom-checkbox"
                checked={selectedFiles.includes(file.id)} // Check if this file is selected
                onChange={() => handleCheckboxChange(file.id)} // Handle individual checkbox change
              />
            </td>
            <td className="file-name-cell">
              <div className="file-icon-and-name">
                <img src="/pdf.svg" alt="PDF Icon" className="file-icon" />
                <div className="file-details">
                  <div className="file-name">{file.name}</div>
                  <div className="file-type">{file.type}</div>
                </div>
              </div>
            </td>
            <td>{file.type}</td>
            <td>{file.size}</td>
            <td>
              <span>{file.time_ago}</span>
              <br />
              <span>{file.uploaded_at}</span>
            </td>
            <td className="chat-button-cell">
              <div
                className="chat-button-doc"
                onClick={() => {
                  navigate("/chat", {
                    state: { pdfUrl: file.pdf_url, pdfKey: file.key },
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src="/chat-icon.svg"
                  alt="Chat Icon"
                  className="chat-icon"
                />
                <span>Chat</span>
              </div>
            </td>
            <td>
              <img
                src="/action.svg"
                alt="Actions"
                width="20"
                height="20"
                style={{ cursor: "pointer" }}
                onClick={() => toggleActionMenu(index)} // Toggle action menu for each document
              />
              {actionMenuIndex === index && (
                <div
                ref={(el) => (dropdownRefs.current[index] = el)}
                className="document-action-menu-underfolder"
              >                 {/* Rename Action */}
                  <div
                    className="document-action-item"
                    onClick={() => {
                      setCurrentFile(file); // Set the file for renaming
                      setRenameInput(file.name); // Set the current name in input field
                      setIsRenameModalOpen(true); // Open rename modal
                    }}
                  >
                    <img
                      src="/edit.svg"
                      alt="Edit Icon"
                      className="document-action-icon"
                    />
                    <p>Rename</p>
                  </div>

                  {/* Delete Action */}
                  <div
                    className="document-action-item"
                    onClick={() => {
                      setCurrentFile(file); // Set the file for deletion
                      setIsDeleteModalOpen(true); // Open delete modal
                    }}
                  >
                    <img
                      src="/delete.svg"
                      alt="Delete Icon"
                      className="document-action-icon"
                    />
                    <p>Delete</p>
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))
      ) : (
        <tr>
        <td colSpan="7" className="no-documents-message-cell">
          <div className="no-documents-container flex flex-col items-center justify-center mr-2rem">
            <img
              src="/no-contracts.svg"
              alt="No Documents Found"
              className="no-documents-icon mb-4"
            />
            <p className="no-documents-message text-center text-gray-500">
              Looks like your Table is empty!
            </p>
          </div>
        </td>
      </tr>
      
      )}
    </tbody>
    
  </table>

  {/* {files.documents && files.documents.length > 0 && (
    <div className="pagination-underfolder">
      <div className="pagination-dropdown">
        <select
          value={currentPage}
          onChange={(e) => setCurrentPage(Number(e.target.value))}
          className="page-selector"
        >
          {Array.from({ length: totalPages }, (_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
      </div>
      <span className="contentDown">of {totalPages} pages</span>
      <button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
      >
        <img
          src="/decrease.svg"
          alt="Previous Page"
          style={{ height: "12px", width: "12px" }}
        />
      </button>
      <button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        style={{
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
        }}
      >
        <img
          src="/increase.svg"
          alt="Next Page"
          style={{ height: "12px", width: "12px" }}
        />
      </button>
    </div>
  )} */}
</div>
{isPopupVisible && (
  <div className="popup-container-underfolder">
    <div className="popup-content-folder">
      <h2>Oops! Maximum Limit Reached</h2>
      <p>This folder allows a maximum of 5 documents. To upload more, please delete an existing document.</p>
      <button
        className="popup-close-button-folder"
        onClick={() => setIsPopupVisible(false)} // Close the popup
      >
        Got It
      </button>
    </div>
  </div>
)}



      {loading && <div className="loading-indicator">Deleting documents...</div>}


      <FilterModalForFolder
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onApply={handleApplyFilters}
        setFiles={setFiles}
        setFolders={setFolders}
        folderId={folderId}
        src = "folder"
      />

<UploadModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onUploadSuccess={handleUploadSuccess}
        folderId={folderId} // Pass folderId to UploadModal
        type={"underFolder"} // Specify type as "document"
      />

      <RenameModal
        isOpen={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
        onRename={handleRename}
        fileName={renameInput}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
        fileName={currentFile ? currentFile.name : ""}
      />

      <RenameModal
        isOpen={isRenameFolderModalOpen}
        onClose={() => setIsRenameFolderModalOpen(false)}
        onRename={handleRenameFolder}
        fileName={renameFolderInput}
      />

      <DeleteModal
        isOpen={isDeleteFolderModalOpen}
        onClose={() => setIsDeleteFolderModalOpen(false)}
        onDelete={() => handleDeleteFolder(currentFolder?.id)}
        fileName={currentFolder ? currentFolder.name : ""}
      />
    </div>
  );
};

export default FolderContents;
