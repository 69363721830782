import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPdfChat, resetPdfChatState } from "../../Features/pdfChatSlice";
import { Header } from "../../components/Header/Header";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./chat.css";
const PdfChat = () => {
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const status = useSelector((state) => state.chat.status);
  const [clickedIndex, setClickedIndex] = useState(null); // Track the clicked box
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const textareaRef = useRef(null);

  const [isSending, setIsSending] = useState(false); // Track sending state

  const [chatHistory, setChatHistory] = useState([
    { id: Date.now(), sender: "bot", text: "hello, How can i help you" }, // Default bot message
  ]); // Persistent chat history
  // Redux state selectors
  const partialAnswer = useSelector(
    (state) => state.pdfChat?.partialAnswer || ""
  );
  const finalAnswer = useSelector((state) => state.pdfChat?.finalAnswer || "");
  const loading = useSelector((state) => state.pdfChat?.status === "loading");
  const error = useSelector((state) => state.pdfChat?.error);
  const { botMessageId, runId , generatedQuestions } = useSelector((state) => state.pdfChat);
  console.log("hfdhfj",generatedQuestions)
  const location = useLocation();
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);

  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [typedDummyMessages, setTypedDummyMessages] = useState([]);


  const { pdfKey: dynamicPdfKey, pdfUrl: dynamicPdfUrl } = location.state || {};
  const pdfKey = dynamicPdfKey || " ";
  const pdfUrl = dynamicPdfUrl || " ";
  // const pdfKey = "_124_275_41f5f86e-c5bd-4ef5-843c-2aedd8a9cedf";
  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");

    return `<p>${formattedText}</p>`;
  }, []);
  console.log("bot", botMessageId);
  console.log("run", runId);

  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [clientMessage, setClientMessage] = useState("");

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  // const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);


  const handleSend = (messageToSend) => {
    if (isAwaitingResponse) return;
    const message = messageToSend || clientMessage;
    if (message.trim() && pdfKey) {
        const userMessage = { id: Date.now(), sender: "user", text: message };
        setChatHistory((prev) => [...prev, userMessage]); // Add user message to chat
        setIsAwaitingResponse(true);
        setClientMessage(""); 
        setTextAreaHeight("auto");
        setShowDummyMessages(false);
        setClickedIndex(null);
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto"; // Reset to initial height
        }

        dispatch(fetchPdfChat({ pdfKey, clientMessage: message })); // Dispatch Redux action
    }
};
useEffect(() => {
  if (status === "succeeded" || status === "failed") {
    setIsAwaitingResponse(false); // Allow sending new messages
  }
}, [status]);
useEffect(() => {
  if (textareaRef.current) {  // Ensure textareaRef is available
    textareaRef.current.style.height = textAreaHeight; // Apply height when it's available
  }

  // Cleanup function to flush the ref after the height is set
  return () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = textAreaHeight; // Reset the height when not in use
    }
  };
}, [textAreaHeight]);
  
  
  // Re-show dummy questions after the bot's response

  
  
  useEffect(() => {
    const fetchSummary = async () => {
      if (pdfKey) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/readpdf/summary-and-questions/?pdf_key=${pdfKey}`,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          const { summary, example_questions } = response.data;
  
          // Stream the summary
          streamText(summary, (chunk) => {
            setChatHistory((prev) => [
              { id: Date.now(), sender: "bot", text: chunk }, // Update with streamed chunk
              ...prev.slice(1),
            ]);
          }, 19);
  
          // Process and stream only the first two example questions
          if (example_questions) {
            const questions = example_questions.split(";").map((q) => q.trim());
            const limitedQuestions = questions.slice(0, 2); // Ensure only 2 questions are processed
            setTypedDummyMessages(new Array(2).fill("")); // Initialize array with two empty strings
  
            limitedQuestions.forEach((question, index) => {
              streamText(question, (chunk) => {
                setTypedDummyMessages((prev) => {
                  const updatedMessages = [...prev];
                  updatedMessages[index] = chunk; // Update streamed chunk for each question
                  return updatedMessages;
                });
              }, 20); // Slower speed for question streaming
            });
          }
        } catch (error) {
          console.error("Error fetching summary and questions:", error);
        }
      }
    };
  
    fetchSummary();
  }, [pdfKey]);
  
  
  
  


  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        } else {
          return [
            ...prev,
            { id: Date.now(), sender: "bot", text: partialAnswer, final: false },
          ];
        }
      });
    }
  }, [partialAnswer]);
  
  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index); // Highlight the clicked dummy question
      setClientMessage(dummyMessage.trim()); // Set the dummy message in the input field
    }
  };
  
  const streamDummyMessages = (questions) => {
    const maxToShow = 2; // Number of dummy questions to display
    const limitedQuestions = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(maxToShow).fill("")); // Initialize empty messages

    limitedQuestions.forEach((question, index) => {
        let charIndex = 0;
        const interval = setInterval(() => {
            setTypedDummyMessages((prev) => {
                const updatedMessages = [...prev];
                updatedMessages[index] = question.slice(0, charIndex + 1);
                return updatedMessages;
            });

            charIndex += 1;

            if (charIndex === question.length) clearInterval(interval);
        }, 10); // Typing speed
    });
};

const streamText = (text, callback, delay = 10) => {
  let charIndex = 0;
  const interval = setInterval(() => {
    const chunk = text.slice(0, charIndex + 1); // Add one character at a time
    callback(chunk);

    charIndex += 1;
    if (charIndex >= text.length) {
      clearInterval(interval); // Stop when all characters are streamed
    }
  }, delay);
};


  

  useEffect(() => {
    if (partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot" && !lastMessage.final) {
          lastMessage.text = partialAnswer;
          return [...prev];
        } else if (
          !prev.some(
            (msg) => msg.sender === "bot" && msg.text === partialAnswer
          )
        ) {
          return [
            ...prev,
            { sender: "bot", text: partialAnswer, final: false },
          ];
        }
        return prev;
      });
    }
  }, [partialAnswer]);
  useEffect(() => {
    if (finalAnswer) {
        setChatHistory((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage?.sender === "bot" && !lastMessage.final) {
                lastMessage.text = finalAnswer;
                lastMessage.final = true;
                return [...prev];
            } else if (
                !prev.some((msg) => msg.sender === "bot" && msg.text === finalAnswer)
            ) {
                return [...prev, { sender: "bot", text: finalAnswer, final: true }];
            }
            return prev;
        });
        setIsAwaitingResponse(false);
        
        // Dummy message streaming happens only after final answer
        if (generatedQuestions) {
            const questions = generatedQuestions.split(";").map((q) => q.trim());
            streamDummyMessages(questions.slice(0, 2));
        }

        setShowDummyMessages(true); // Ensure dummy messages are only shown here
    }
}, [finalAnswer, generatedQuestions]);


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);


  const submitFeedback = async () => {
    try {
      if (!messageIdToFeedback || !runIdToFeedback || !selectedFeedbackType) {
        console.error("Missing required data for feedback submission.");
        return;
      }
  
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: messageIdToFeedback, // Use message-specific ID
          run_id: runIdToFeedback,         // Use run-specific ID
          feedback_type: selectedFeedbackType,
          user_feedback: feedbackMessage || "", // Optional feedback message
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  
  
  
    
  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle thumbs-up for the specific message
    }));
  
    // Turn off thumbs-down if it is currently active
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false, // Reset thumbs-down for this message
    }));
  
    if (!thumbsUpClicked[messageId]) {
      // Show popup and submit feedback for the specific message
      setPopupVisible(true);
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback(); // Optional: Trigger the feedback submission
    }
  };
  
  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle thumbs-down for the specific message
    }));
  
    // Turn off thumbs-up if it is currently active
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false, // Reset thumbs-up for this message
    }));
  
    if (!thumbsDownClicked[messageId]) {
      // Show thumbs-down feedback popup for the specific message
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };
  
  
  
  
  useEffect(() => {
    if (status === "succeeded") {
      const newBotMessage = { id: Date.now(), sender: "bot", text: finalAnswer };

      // Add AI's response to the chat history
      setChatHistory((prev) => [...prev, newBotMessage]);
    }
  }, [status, finalAnswer]);
  
  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId); // Set the copied message ID
        setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };
  
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });
  
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleSubmitThumbsDownFeedback = async () => {
    // Gather selected checkbox feedback
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
  
    // Combine selected feedback and placeholder message
    const combinedFeedback = [
      ...selectedFeedback,
      feedbackMessage,
    ].join("\n");
  
    setFeedbackMessage(combinedFeedback);  // Update feedbackMessage before submitting
  
    try {
      // Submit feedback
      await submitFeedback();
  
      // After successful submission, reset the state
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");  // Clear the feedback message
  
      // Close the popup
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  
  const handleClosePopup = () => {
    setPopupVisible(false);
    setShowThumbsDownPopup(false);
  
    // Reset feedback state
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage(""); // Reset feedback message
  };
  
  
  useEffect(() => {
    return () => {
      dispatch(resetPdfChatState());
    };
  }, [dispatch]);
  useEffect(() => {
    if (generatedQuestions) {
      const questions = generatedQuestions.split(";").map((q) => q.trim());
      streamDummyMessages(questions); // Start streaming the dummy messages
    }
  }, [generatedQuestions]);
  
  
  useEffect(() => {
    if (chatHistory.length > 0 && chatHistory[chatHistory.length - 1]?.sender === "bot") {
      const newMessageId = chatHistory[chatHistory.length - 1]?.id;
  
      setThumbsUpClicked((prev) => ({
        ...prev,
        [newMessageId]: false, // Initialize thumbs-up state for the new message
      }));
  
      setThumbsDownClicked((prev) => ({
        ...prev,
        [newMessageId]: false, // Initialize thumbs-down state for the new message
      }));
    }
  }, [chatHistory]);
  
  
  

  // Runs whenever chatHistory changes
  

 
  


  
  

  
  
  

  
  
  

  return (
    <div className="full-container">
      <div className="custom-header-wrapper">
        <Header icon="/arrow-back.svg" name="Go to Documents" />
      </div>
      <div className="content-container">
        <div className="left-side">
          <div className="message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => (
              <div
                key={index}
                className={`mb-2 ${
                  message.sender === "user"
                    ? "w-full flex justify-end"
                    : "bot-message-container w-full flex items-start"
                }`}
              >
                {message.sender === "bot" && (
                  <div className="bot-icon-container flex-shrink-0">
                    <img
                      src="/bot-icon.svg"
                      alt="Bot Icon"
                      className="bot-icon h-6 w-6 rounded-full mr-2"
                    />
                  </div>
                )}
                {/* Bot Message with Flexbox */}
                {message.sender === "bot" ? (
                  <div className="flex flex-col">
                    {/* Message Bubble */}
                    <span
                      className="bot-message-content"
                      dangerouslySetInnerHTML={{
                        __html: formatText(message.text),
                      }}
                    />
                    {/* Reaction Icons */}
                    <div className="reactions flex gap-2 text-gray-500 mt-2">
                    
<span
  className="reaction-icon cursor-pointer"
  onClick={() => handleThumbsUpClick(message.id)}
>
  <img
    src={
      thumbsUpClicked[message.id]
        ? "/thums-up-filled.svg" // Filled icon when thumbs-up is clicked
        : "/thums-up.svg"       // Default icon
    }
    alt="Thumbs Up"
  />
</span>


<span
  className="reaction-icon cursor-pointer"
  onClick={() => handleThumbsDownClick(message.id)}
>
  <img
    src={
      thumbsDownClicked[message.id]
        ? "/thums-down-filled.svg" // Filled icon when thumbs-down is clicked
        : "/thums-down.svg"       // Default icon
    }
    alt="Thumbs Down"
  />
</span>
<span
  className="reaction-icon cursor-pointer"
  onClick={() => handleCopy(message.id, message.text)}
>
  <img
    src={
      copiedMessageId === message.id
        ? "/copy-clicked.svg" // Use this SVG when the message is copied
        : "/copy.svg"         // Default SVG
    }
    alt="Copy"
  />
</span>
                    </div>
                  </div>
                ) : (
                  <span className="user-message-bubble">{message.text}</span>
                )}
              </div>
            ))}
            {error && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm rounded-lg p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>
          <div className="dummy-container flex flex-col gap-2 md:gap-0 mr-20 ">
          {showDummyMessages &&
  typedDummyMessages.map((question, index) => (
    <div
      key={index}
      className={`dummy-message-box border border-purple-400 rounded-lg p-4 mt-4 flex items-center cursor-pointer ${
        clickedIndex === index ? "opacity-50" : "opacity-100"
      }`}
      onClick={() => handleDummyMessageClick(question.trim(), index)}
    >
      <span className="icon mr-2">
        <img src="/star-dot.svg" alt="Star Icon" />
      </span>
      <p className="text-sm">{question.trim()}</p>
    </div>
  ))}
  </div>








<div className="custom-input-wrapper mt-10">
  <div className="input-container-holder">
    <textarea
      placeholder="Ask me anything"
      className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
      value={clientMessage} // Shows the selected dummy question or user's input
      onChange={(e) => { setClientMessage(e.target.value); // Updates message state
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; // Reset height
        textareaRef.current.style.height = `${Math.min(e.target.scrollHeight, 88)}px`; // Set dynamic height
      }
    }}
    onKeyDown={(e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault(); // Prevent new line
        if (clientMessage.trim()) {
          handleSend(clientMessage.trim()); // Send the message if it's not empty
          setClientMessage(""); // Clear the input field
        }
      }
    }}
    ref={textareaRef} // Ensure ref is attached
    style={{
      height: textAreaHeight,
      boxSizing: "border-box",
    }}
    ></textarea>
    <button className="send-button-holder" onClick={() => handleSend()} disabled={isAwaitingResponse} >
    {isAwaitingResponse ? (
    <img src="/loading-send-button.svg" alt="Loading" /> // Loading SVG during the waiting period
  ) : (
    <img src="/send.svg" alt="Send" /> // Default send SVG
  )}    </button>
  </div>
</div>

        </div>
        <div className="right-side">
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            className="w-full h-full"
            allow="fullscreen"
          />
        </div>
      </div>
     

      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            {/* Top Section: Icon and Close Button */}
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div class="checkbox-container-thumsup">
                <label className="thumsup-checkbox">
  <input
    type="checkbox"
    className="thumbsup1"
    id="factuallyIncorrect"
    checked={selectedCheckboxes.factuallyIncorrect}
    onChange={handleCheckboxChange}
  />
  Not factually correct.
</label>

<label className="thumsup-checkbox">
  <input
    type="checkbox"
    className="thumbsup2"
    id="refused"
    checked={selectedCheckboxes.refused}
    onChange={handleCheckboxChange}
  />
  Refused when it shouldn’t have.
</label>

<label className="thumsup-checkbox">
  <input
    type="checkbox"
    className="thumbsup3"
    id="notFollowingInstructions"
    checked={selectedCheckboxes.notFollowingInstructions}
    onChange={handleCheckboxChange}
  />
  Not Following Instructions.
</label>

                </div>
                <div class="line-with-text">
                  <span>Or</span>
                </div>
                <div class="rectangular-box">
                  <div class="feedback-container">
                  <textarea
              className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
              maxLength="250"
              placeholder="Your feedback is much appreciated and helps improve the AI!"
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
            />
                  </div>
                  <p class="character-count">Maximum: 250 characters</p>
                </div>

                <button className="custom-button-thumsdown" onClick={handleSubmitThumbsDownFeedback}>
          Submit
        </button>              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PdfChat;
