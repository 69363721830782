import React, { useState, useEffect } from "react";
import "./FilterModal.css";
import axios from "axios";

const FilterModal = ({ isOpen, onClose, onApply, setFiles, setFolders }) => {
  const [token, setToken] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    folder: true,
    normalDocument: true,
    contract: false,
    invoice: false,
  });
  const [fileType, setFileType] = useState([]);
  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [rangeDate, setRangeDate] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);

  const [activeTab, setActiveTab] = useState("FileType"); // Default tab is FileType
  const clearFilters = () => {
    setSelectedFilters({
      folder: true,
      normalDocument: true,
      contract: false,
      invoice: false,
    });
    setFileType([]); // Reset the file types
  };
  

  const handleApply = async () => {
    try {
      const queryParams = {
        file_type: fileType.length > 0 ? fileType.join(",") + "," : "folder,", // Default to "folder," if no file types selected
      };
  
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/readpdf/filter-files-and-folders/?file_type=folder,`,
        {
          params: queryParams,
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 200) {
        setFolders(response.data.folders || []); // Ensure arrays default to empty
        setFiles(response.data.files || []);
      }
    } catch (err) {
      console.error("Error applying filters:", err);
    }
  
    onClose(); // Close modal after applying filters
  };
  
  

  const handleDateRangeChange = (e) => {
    const value = e.target.value;
    setRangeDate(value);

    // Set the date range based on selection
    const today = new Date();
    if (value === "today") {
      setFromDate(today.toISOString().split("T")[0]);
      setToDate(today.toISOString().split("T")[0]);
    } else if (value === "last7days") {
      const last7Days = new Date(today);
      last7Days.setDate(today.getDate() - 7);
      setFromDate(last7Days.toISOString().split("T")[0]);
      setToDate(today.toISOString().split("T")[0]);
    } else if (value === "thisyear") {
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      setFromDate(startOfYear.toISOString().split("T")[0]);
      setToDate(today.toISOString().split("T")[0]);
    }
  };
  const handleCheckboxChange = (type) => {
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev, [type]: !prev[type] };
  
      // Map selectedFilters to fileType array
      const updatedFileTypes = Object.entries(updatedFilters)
        .filter(([key, isChecked]) => isChecked)
        .map(([key]) => {
          switch (key) {
            case "folder":
              return "folder";
            case "normalDocument":
              return "normal_document";
            case "contract":
              return "contract";
            case "invoice":
              return "invoice";
            default:
              return null;
          }
        })
        .filter(Boolean); // Remove null values
  
      setFileType(updatedFileTypes); // Update fileType array
      return updatedFilters; // Update selectedFilters
    });
  };
  
  
  
  const safeToLowerCase = (str) => {
    return str && typeof str === 'string' ? str.toLowerCase() : ''; // Only call toLowerCase on non-null strings
  };
  

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay-doc">
      <div className="filter-modal-doc">
        <h2>Filter By</h2>
        <div className="Display">
          <div className="Box-rec">
            {/* Tabs to switch between filters */}
            <button
              className={`Box-doc ${activeTab === "FileType" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("FileType")}
            >
              <img src="./File-type.svg" alt="" />
              File Type
            </button>
            {/* <button
              className={`Box-doc ${activeTab === "FileSize" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("FileSize")}
            >
              <img src="./so.svg" alt="" />
              File Size
            </button> */}
            {/* <button
              className={`Box-doc ${activeTab === "UploadDate" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("UploadDate")}
            >
              <img src="./so.svg" alt="" />
              Upload Date
            </button> */}
          </div>

          {/* Conditional rendering based on active tab */}
          {activeTab === "FileType" && (
            <div className="filter-options">
              <div className="Header-doc-filter-folder">
                <p>Select the type of files to include in the results</p>
              </div>
              <label>
  <input
    type="checkbox"
    checked={selectedFilters.folder}
    onChange={() => handleCheckboxChange("folder")}
    className="chekcbox-doc-filter"
  />
  Folder
</label>
<label>
  <input
    type="checkbox"
    checked={selectedFilters.normalDocument}
    onChange={() => handleCheckboxChange("normalDocument")}
    className="chekcbox-doc-filter"
  />
  Normal Document
</label>
<label>
  <input
    type="checkbox"
    checked={selectedFilters.contract}
    onChange={() => handleCheckboxChange("contract")}
    className="chekcbox-doc-filter"
  />
  Contract
</label>
<label>
  <input
    type="checkbox"
    checked={selectedFilters.invoice}
    onChange={() => handleCheckboxChange("invoice")}
    className="chekcbox-doc-filter"
  />
  Invoice
</label>

            </div>
          )}

          {/* {activeTab === "FileSize" && (
            <div className="filter-options">
              <div className="Header-doc-filter">
                <p>Filter results by file size</p>
              </div>
              <div className="slider-container">
                <input
                  type="range"
                  min="0"
                  max="2000"
                  className="slider"
                  value={minSize}
                  onChange={(e) => setMinSize(e.target.value)}
                />
                <div className="slider-values">
                  <span>0 MB</span>
                  <span>2 MB</span>
                </div>
              </div>
            </div>
          )}

          {activeTab === "UploadDate" && (
            <div className="filter-options">
              <div className="Header-doc-filter-folder">
                <p>Quick select</p>
              </div>
              <div className="dropdown-container-folder">
                <select className="dropdown-filter-folder-here" onChange={handleDateRangeChange}>
                  <option value="" disabled selected>
                    <p className="select-here-folder">Select here</p>
                  </option>
                  <option value="today">Today</option>
                  <option value="last7days">Last 7 Days</option>
                  <option value="thisyear">This Year</option>
                </select>
              </div>
              <div className="or-divider">
                <span>Or</span>
              </div>
              <div className="date-range-container-folder">
                <div className="date-picker-folder">
                  <label className="date-label-folder">From</label>
                  <div className="date-input-folder">
                    <img src="./calendar-icon.svg" alt="Calendar Icon" />
                    <input
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="date-picker-folder">
                  <label className="date-label-folder">To</label>
                  <div className="date-input-folder">
                    <img src="./calendar-icon.svg" alt="Calendar Icon" />
                    <input
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
        <div className="filter-actions-folder">
        <button onClick={clearFilters} className="clear-all-filter">
  Clear All
</button>          <button onClick={handleApply}className="apply-filter">Apply filter</button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
