import React from "react";
import "./UploadModal"; // Optional: Create this file to keep styles scoped

const UploadProgress = ({ type, progress }) => {
  return (
    <div>
    <div className="title-name">
      <h2 className="modal-title">Create a new folder</h2>
    </div>
    <p className="modal-description">
      Name your folder and drag & drop documents to organize them right
      away
    </p>

    <div className="input-group">
      <label htmlFor="folderName">Title</label>
      <input
        type="text"
        id="folderName"
        
        placeholder="Enter here"
        disabled
      />
    </div>

    <div className="progress-area">
      <img
        src="/uploadingFolderIcon.svg"
        alt="File Icon"
        className="Uploading-icon-doc"
      />
      <p className="doc-uploading-para-one">File Is being Uploaded</p>
      <p className="doc-uploading-para-two">Your Progress</p>
      <div className="progress-container">
        <p className="progress-percentage">{progress}% to complete</p>
       
        <p className="doc-time-estimate">⏱1min</p>
      </div>

      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  </div>
  );
};

export default UploadProgress;
