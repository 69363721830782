import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/Header/Header";
import { fetchChatStream, resetChatState,updatePartialAnswer } from "../../Features/chatSlice";
import "./contractChat.css";
import "./FolderChat.css";
import Spinner from "./Spinner"; 
import axios from "axios";

const FolderChat = () => {
  const chatContainerRef = useRef(null);
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const location = useLocation();
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const folderId = location.state?.folderId;
  const pdfList = location.state?.pdfList || []; // Get PDF list from navigation state
  const [selectedPdf, setSelectedPdf] = useState(pdfList[0]?.pdfUrl || ""); // Default to the first PDF
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null); // Track the clicked box
  const [clientMessage, setClientMessage] = useState(""); // State for client message
  const [activeFolder, setActiveFolder] = useState(null);


  const dispatch = useDispatch();
  const partialAnswer = useSelector((state) => state.chat.partialAnswer);
  const finalAnswer = useSelector((state) => state.chat.finalAnswer);
  const status = useSelector((state) => state.chat.status);
  const error = useSelector((state) => state.chat.error);
    const { botMessageId, runId , generatedQuestions} = useSelector((state) => state.chat);
    const [showDummyMessages, setShowDummyMessages] = useState(true); // New state for dummy messages
    const [typedDummyMessages, setTypedDummyMessages] = useState([]); // Dummy message content

    console.log("Generated Questions:", generatedQuestions);
    const [textAreaHeight, setTextAreaHeight] = useState('auto');
    const textareaRef = useRef(null);

  const { pdfUrl, pdfKey } = location.state || {};
  const [chatHistory, setChatHistory] = useState([
    { id: Date.now(), sender: "bot", text: "" }, // Default bot message
  ]); 
  
  const [isSending, setIsSending] = useState(false); // Track sending state

  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, '<br/>') // Line breaks
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Bold text
      .replace(/__(.*?)__/g, '<u>$1</u>') // Underlined text
      .replace(/~~(.*?)~~/g, '<del>$1</del>') // Strikethrough text
      .replace(/###(.*?)/g, '<strong>$1</strong>') // Strong emphasis
      .replace(/^-\s(.+)/gm, '<li>$1</li>') // List items
      .replace(/(<li>.*<\/li>)/gs, '<ul>$1</ul>') // Wrap list items in <ul>
      .replace(/^([^:\n]+):\s*(.+)$/gm, '<b>$1:</b> $2'); // Key-value pairs

    return `<p>${formattedText}</p>`;
  }, []);
  console.log("bot", botMessageId);
  console.log("run", runId);

  // Scroll button visibility logic
  useEffect(() => {
    const handleScroll = () => {
      const shouldShow =
        chatContainerRef.current.scrollTop <
        chatContainerRef.current.scrollHeight -
          chatContainerRef.current.clientHeight -
          100;
      setShowScrollButton(shouldShow);
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const submitFeedback = async () => {
    try {
      // Ensure message_id and run_id are set correctly
      if (!botMessageId || !runId || !selectedFeedbackType) {
        console.error("Missing required data for feedback submission.");
        return;  // Stop submission if any required fields are missing
      }
  
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: botMessageId, // Ensure botMessageId is set
          run_id: runId,             // Ensure runId is set
          feedback_type: selectedFeedbackType, // Ensure feedback_type is "up" or "down"
          user_feedback: feedbackMessage || "", // Optional feedback message
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // Check response
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

    
  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle thumbs-up for the specific message
    }));
  
    // Turn off thumbs-down if it is currently active
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false, // Reset thumbs-down for this message
    }));
  
    if (!thumbsUpClicked[messageId]) {
      // Show popup and submit feedback for the specific message
      setPopupVisible(true);
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback(); // Optional: Trigger the feedback submission
    }
  };
  
  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle thumbs-down for the specific message
    }));
  
    // Turn off thumbs-up if it is currently active
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false, // Reset thumbs-up for this message
    }));
  
    if (!thumbsDownClicked[messageId]) {
      // Show thumbs-down feedback popup for the specific message
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };
  
  useEffect(() => {
    const fetchFolderSummaryAndQuestions = async () => {
      try {
        const token = localStorage.getItem("token");
  
        // API call to fetch folder summary and questions
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/readpdf/folder-summary-and-questions/${folderId}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        const { meta_summary, sample_questions } = response.data;
  
        // Update chat history with the fetched meta_summary
        if (meta_summary) {
          setChatHistory([
            { id: Date.now(), sender: "bot", text: meta_summary },
          ]);
        }
  
        // Process and display the sample questions
        if (sample_questions) {
          const questions = sample_questions.split(";").map((q) => q.trim());
          streamDummyMessages(questions.slice(0, 2)); // Display the first two questions
        }
      } catch (error) {
        console.error("Error fetching folder summary and questions:", error);
      }
    };
  
    if (folderId) {
      fetchFolderSummaryAndQuestions();
    }
  }, [folderId]);
  
  
  

  useEffect(() => {
    if (finalAnswer) {
      setChatHistory((prev) => [
        ...prev,
        { id: Date.now(), sender: "bot", text: finalAnswer, final: true },
      ]);
  
      // Stream dummy messages after the final response
      if (generatedQuestions) {
        const questions = generatedQuestions.split(";").map((q) => q.trim());
        streamDummyMessages(questions.slice(0, 2)); // Stream only the first two questions
      }
      setClickedIndex(null); // Reset clicked dummy message

      setShowDummyMessages(true); // Ensure dummy messages are displayed
    }
  }, [finalAnswer, generatedQuestions]);
  

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId); // Set the copied message ID
        setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };
  
  
  
 
  

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });
  
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  

  const handleSubmitThumbsDownFeedback = async () => {
    // Gather selected checkbox feedback
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }
  
    // Combine selected feedback and placeholder message
    const combinedFeedback = [
      ...selectedFeedback,
      feedbackMessage,
    ].join("\n");
  
    setFeedbackMessage(combinedFeedback);  // Update feedbackMessage before submitting
  
    try {
      // Submit feedback
      await submitFeedback();
  
      // After successful submission, reset the state
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage("");  // Clear the feedback message
  
      // Close the popup
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };
  
  
  

  const handleClosePopup = () => {
    setShowThumbsDownPopup(false);
    
    // Reset feedback state when popup is closed without submission
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });
    setFeedbackMessage("");  // Reset feedback message
  };

  // Reset chat state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetChatState());
    };
  }, [dispatch]);
  
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  // const [thumbsUpClicked, setThumbsUpClicked] = useState(false);

  const streamDummyMessages = (questions) => {
    const maxToShow = 2; // Number of dummy questions to display
    const limitedQuestions = questions.slice(0, maxToShow);
    setTypedDummyMessages(new Array(maxToShow).fill("")); // Initialize empty messages

    limitedQuestions.forEach((question, index) => {
      let charIndex = 0;
      const interval = setInterval(() => {
        setTypedDummyMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[index] = question.slice(0, charIndex + 1);
          return updatedMessages;
        });

        charIndex += 1;

        if (charIndex === question.length) clearInterval(interval);
      }, 10); // Typing speed
    });
  };


  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  const handleSend = () => {
    if (isAwaitingResponse) return;
    if (inputMessage.trim()) {
      setChatHistory((prev) => [
        ...prev,
        { id: Date.now(), sender: "user", text: inputMessage },
      ]);
      setIsAwaitingResponse(true);
      setInputMessage(""); // Clear the input field
      setClientMessage(""); 
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; // Reset to initial height
      }
      dispatch(
        updatePartialAnswer(
          "<div class='spinner-wrapper'><div class='spinner'></div></div>"
        )
      );

      // Include folderId in the action payload
      dispatch(fetchChatStream({ query: inputMessage, folderId }));
  
      setShowDummyMessages(false); // Hide dummy questions after sending
      setClickedIndex(null); // Reset clicked dummy message
    } else {
      console.error("Message cannot be empty");
    }
  };
  useEffect(() => {
    if (status === "succeeded" || status === "failed") {
      setIsAwaitingResponse(false); 
      dispatch(updatePartialAnswer(""));// Allow sending new messages
    }
  }, [status]);
  
  useEffect(() => {
    if (textareaRef.current) {  // Ensure textareaRef is available
      textareaRef.current.style.height = textAreaHeight; // Apply height when it's available
    }
  
    // Cleanup function to flush the ref after the height is set
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight; // Reset the height when not in use
      }
    };
  }, [textAreaHeight]);
  
  // Append AI's response to the chat history
  useEffect(() => {
    if (status === "succeeded" && finalAnswer) {
      setChatHistory((prev) => {
        // Check if the finalAnswer already exists in the chat history
        if (!prev.some((msg) => msg.text === finalAnswer && msg.sender === "bot")) {
          return [...prev, { id: Date.now(), sender: "bot", text: finalAnswer }];
        }
        return prev;
      });
    }
  }, [status, finalAnswer]);
  
  

  
  const handleDummyMessageClick = (dummyMessage, index) => {
    if (dummyMessage.trim()) {
      setClickedIndex(index); // Highlight the clicked dummy question
      setInputMessage(dummyMessage.trim()); // Set the dummy message in the input field
    }
  };
  
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth", // Add smooth scrolling for a better user experience
      });
    }
  }, [chatHistory]); // Trigger whenever chatHistory updates
  

  useEffect(() => {
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
      
    }
  }, [chatHistory]);

  

  return (
    <>
      <div className="full-container">
        <div className="custom-header-wrapper">
          <Header icon="/arrow-back.svg" name="Go to Documents" />
        </div>
        <div className="content-container">
          {/* Chat Section */}
          <div className="left-side">
            <div className="message-container" ref={chatContainerRef}>
              {chatHistory.map((message, index) => (
                <div
                  key={message.id}
                  className={`mb-2 ${
                    message.sender === "user"
                      ? "w-full flex justify-end"
                      : "bot-message-container w-full flex items-start"
                  }`}
                >
                   {message.sender === "bot" && (
      <div className="bot-icon-container flex-shrink-0">
        <img
          src="/bot-icon.svg"
          alt="Bot Icon"
          className="bot-icon h-6 w-6 rounded-full mr-2"
        />
      </div>
    )}


    {/* Bot Message with Flexbox */}
    {message.sender === "bot" ? (
      <div className="flex flex-col">
        {/* Message Bubble */}
        <span
          className="bot-message-content"
          
          dangerouslySetInnerHTML={{ __html: formatText(message.text) }}
        />

        {/* Reaction Icons */}
        <div className="reactions flex gap-2 text-gray-500 mt-2">
        {/* <span className="reaction-icon cursor-pointer">
  <img src="/charge.svg" alt="Charge" className="charge-icon" />
  <span className="tooltip">Coming Soon</span>
</span> */}
<span
  className="reaction-icon cursor-pointer"
  onClick={() => handleThumbsUpClick(message.id)}
>
  <img
    src={
      thumbsUpClicked[message.id]
        ? "/thums-up-filled.svg" // Filled icon when thumbs-up is clicked
        : "/thums-up.svg"       // Default icon
    }
    alt="Thumbs Up"
  />
</span>


<span
  className="reaction-icon cursor-pointer"
  onClick={() => handleThumbsDownClick(message.id)}
>
  <img
    src={
      thumbsDownClicked[message.id]
        ? "/thums-down-filled.svg" // Filled icon when thumbs-down is clicked
        : "/thums-down.svg"       // Default icon
    }
    alt="Thumbs Down"
  />
</span>
<span
  className="reaction-icon cursor-pointer"
  onClick={() => handleCopy(message.id, message.text)}
>
  <img
    src={
      copiedMessageId === message.id
        ? "/copy-clicked.svg" // Use this SVG when the message is copied
        : "/copy.svg"         // Default SVG
    }
    alt="Copy"
  />
</span>

        </div>
      </div>
    ) : (
      <span className="user-message-bubble">{message.text}</span>
    )}
  </div>
))}
              {/* Bot Messages */}
{/* Bot Messages */}
{status === "loading" && (
  <div className="bot-message-container  w-full flex items-start mb-4">
    {/* Bot Icon */}
    <div className="bot-icon-container flex-shrink-0">
      <img
        src="/bot-icon.svg"
        alt="Bot Icon"
        className="bot-icon h-6 w-6 rounded-full mr-2"
      />
    </div>
    {/* Message Bubble */}
    <div
      className="bot-message-content"
      dangerouslySetInnerHTML={{
        __html: formatText(partialAnswer),
      }}
    />
  </div>
)}




              {status === "failed" && (
                <div className="bot-message-container w-full flex items-start mb-4">
                  <div className="bot-message-content text-red-600 text-sm p-4 max-w-[85%]">
                    <strong>Error:</strong> {error}
                  </div>
                </div>
              )}
            </div>
            <div className="dummy-container flex flex-col gap-2 md:gap-2 mr-20 ">
            {showDummyMessages &&
  typedDummyMessages.map((question, index) => (
    <div
      key={index}
      className={`dummy-message-box border border-purple-400 rounded-lg p-4 mt-4 flex items-center cursor-pointer ${
        clickedIndex === index ? "opacity-50" : "opacity-100"
      }`}
      onClick={() => handleDummyMessageClick(question.trim(), index)} // Set the dummy message in the input
    >
      <span className="icon mr-2">
        <img src="/star-dot.svg" alt="Star Icon" />
      </span>
      <p className="text-sm">{question.trim()}</p>
    </div>
  ))}
</div>




            {/* Input Section */}
            <div className="custom-input-wrapper mt-10">
  <div className="input-container-holder">
  <textarea
  placeholder="Ask me anything"
  className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
  value={inputMessage} // Correct binding to inputMessage
  onChange={(e) => { setInputMessage(e.target.value); // Update inputMessage on user input
  if (textareaRef.current) {
    textareaRef.current.style.height = "auto"; // Reset height
    textareaRef.current.style.height = `${Math.min(e.target.scrollHeight, 88)}px`; // Set dynamic height
  }
}}
onKeyDown={(e) => {
  if (e.key === "Enter" && !e.shiftKey) {
    e.preventDefault(); // Prevent new line
    if (inputMessage.trim()) {
      handleSend(inputMessage.trim()); // Send the message if it's not empty
      setInputMessage(""); // Clear the input field
    }
  }
}}
ref={textareaRef} // Ensure ref is attached
style={{
  height: textAreaHeight,
  boxSizing: "border-box",
}}
   
    ></textarea>
<button className="send-button-holder" onClick={() => handleSend()} disabled={isAwaitingResponse} >
{isAwaitingResponse ? (
    <img src="/loading-send-button.svg" alt="Loading" /> // Loading SVG during the waiting period
  ) : (
    <img src="/send.svg" alt="Send" /> // Default send SVG
  )}</button>

  </div>
</div>
            {showScrollButton && (
              <button
                onClick={scrollToBottom}
                className="scroll-to-bottom fixed bottom-16 right-4 bg-blue-500 text-white rounded-full"
              >
                {/* Add an icon or text here if needed */}
              </button>
            )}
          </div>

          {/* PDF Viewer */}
             <div className="right-side-folder">
            <div className="iframe-folder">
            {/* PDF Viewer */}
            <iframe
              src={selectedPdf}
              title="PDF Viewer"
              className="w-full h-3/4"
              allow="fullscreen"
            />
            </div>
            {/* PDF List */}
            <div className="folder-list-container">
              <div className="folder-list-scrollable">
                {pdfList.map((pdf) => (
                  <div
                    key={pdf.pdfKey}
                    className="folder-box"
                    onClick={() => setSelectedPdf(pdf.pdfUrl)}
                  >
        <div
    className={`folder-icon-container ${activeFolder === pdf.pdfKey ? "active" : ""}`}
    onClick={() => setActiveFolder(pdf.pdfKey)}
  >
      <img
        src="/pdf.svg" // Replace with your PDF icon path
        alt={pdf.name}
        className="folder-icon"
      />
    </div>
    <div className="folder-container relative inline-block group">
  <p className="folder-name truncate w-20 cursor-pointer">{pdf.name}</p>
  <span className="tooltip absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-sm rounded py-1 px-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
    {pdf.name}
  </span>
</div>

                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        



      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            {/* Top Section: Icon and Close Button */}
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div class="checkbox-container-thumsup">
                <label className="thumsup-checkbox">
  <input
    type="checkbox"
    className="thumbsup1"
    id="factuallyIncorrect"
    checked={selectedCheckboxes.factuallyIncorrect}
    onChange={handleCheckboxChange}
  />
  Not factually correct.
</label>

<label className="thumsup-checkbox">
  <input
    type="checkbox"
    className="thumbsup2"
    id="refused"
    checked={selectedCheckboxes.refused}
    onChange={handleCheckboxChange}
  />
  Refused when it shouldn’t have.
</label>

<label className="thumsup-checkbox">
  <input
    type="checkbox"
    className="thumbsup3"
    id="notFollowingInstructions"
    checked={selectedCheckboxes.notFollowingInstructions}
    onChange={handleCheckboxChange}
  />
  Not Following Instructions.
</label>

                </div>
                <div class="line-with-text">
                  <span>Or</span>
                </div>
                <div class="rectangular-box">
                  <div class="feedback-container">
                  <textarea
              className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
              maxLength="250"
              placeholder="Your feedback is much appreciated and helps improve the AI!"
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
            />
                  </div>
                  <p class="character-count">Maximum: 250 characters</p>
                </div>

                <button className="custom-button-thumsdown" onClick={handleSubmitThumbsDownFeedback}>
          Submit
        </button>              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default FolderChat;
