import React from "react";
import { useNavigate , useLocation } from "react-router-dom";

export const Header = ({ name, icon, bell, search, askCoPiolt, contractKey }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleAskCopilotClick = () => {
    navigate(`/Contract/contractChat?ckey=${contractKey}`); 
  };

  const handleGoToContractsClick = () => {
    const searchParams = new URLSearchParams(location.search);
    const folderId = searchParams.get("folderId")
    if (folderId) {
      // If the user is in a folder, navigate back to the Documents page
      navigate(`/uploadFile`);
    }
    else if (location.pathname === "/Contract") {
      window.location.reload();
    } else if (location.pathname === "/uploadFile") {
      window.location.reload();
    } else if (
      location.pathname.includes("/chat") || 
      location.pathname.includes("/Contract/FolderChat")
    ) {
      navigate("/uploadFile"); 
    } else {
      navigate("/Contract");
    }
  };
  
  
  

  

  const isContractChatPage = location.pathname.includes("contractChat");
  const isChatPage = location.pathname.includes("/chat");
  const isFolderChat = location.pathname.includes("/Contract/FolderChat");

  return (
    <div className="flex">
      <header className="flex justify-between items-center w-full h-[70px] bg-[#fff] border border-black/10 pr-4">
        
        {/* Go to Contracts Section - Now a button */}
        <button
          className="flex items-center ml-[200px] cursor-pointer bg-transparent border-none"
          onClick={handleGoToContractsClick}
          style={{
            marginLeft: "200px", // Always applied
            ...(isContractChatPage || isChatPage || isFolderChat ? { marginLeft: "40px" } : {}),// Chat page-specific margin// Conditionally applied
          }}
        >
          {icon && <img src={icon} alt="icon" className="h-5 w-5 mr-2" />}
          <h2
            className="mr-4"
            style={{
              color: "#000",
              fontFamily: "Outfit, sans-serif",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            {name}
          </h2>
        </button>

        {/* Ask Copilot Button */}
        {askCoPiolt && (
          <button
            className="ask-copilot-button flex justify-center ml-auto mr-8"
            onClick={handleAskCopilotClick} // Using the function to ensure consistency
          >
            <img src="/ai-icon.svg" alt="AI Icon" className="h-4 w-4" />
            <span>Ask Copilot</span>
          </button>
        )}

        {/* Search and Bell Icons */}
        <div className="flex justify-end">
          <div className="flex justify-center items-center">
            {/* Search Icon */}
            {search && (
              <button className="bg-transparent border-none">
                <img src={search} alt="Search Icon" style={{ marginRight: '20px' }} />
              </button>
            )}

            {/* Bell Icon */}
            {bell && (
              <button className="bg-transparent border-none">
                <img src={bell} alt="Bell Icon" />
              </button>
            )}
          </div>
        </div>
      </header>

      {/* Styles */}
      <style jsx>{`
        .ask-copilot-button {
          display: inline-flex;
          padding: 10px 28px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          background: linear-gradient(91deg, #8b55dd 7.29%, #9177b8 99.49%);
          color: #eaf7ff;
          font-family: Outfit, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.16px;
          cursor: pointer;
          border: none;
        }
      `}</style>
    </div>
  );
};
