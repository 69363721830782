import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Async thunk for fetching chat responses for PDFs
export const fetchPdfChat = createAsyncThunk(
  'pdfChat/fetchPdfChat',
  async ({ pdfKey, clientMessage }, { rejectWithValue, dispatch }) => {
    const token = localStorage.getItem('token'); // Replace with actual token handling logic
    if (!token) {
      return rejectWithValue('No authorization token found');
    }
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/readpdf/qdrant-chat/?pdf_key=${pdfKey}`;
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({ client_message: clientMessage }),
      });
      if (!response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType?.includes('application/json')) {
          const error = await response.json();
          return rejectWithValue(error.message || 'Failed to fetch PDF chat response');
        } else {
          const errorText = await response.text();
          return rejectWithValue(`Unexpected response: ${errorText}`);
        }
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      dispatch(updatePartialAnswer('Generating...')); // Set typing indicator initially
      let accumulatedAnswer = '';
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.trim().split('\n');
        for (const line of lines) {
          if (!line.trim()) continue;
          try {
            const jsonLine = line.startsWith('data: ') ? line.slice(6).trim() : line.trim();
            const parsedLine = JSON.parse(jsonLine);
            if (parsedLine.ans) {
              accumulatedAnswer += parsedLine.ans;
              dispatch(updatePartialAnswer(accumulatedAnswer)); // Update real-time response
            }
            if (parsedLine.type === 'final') {
              // Extract bot_message_id and run_id when type is "final"
              botMessageId = parsedLine.data.bot_message_id;
              runId = parsedLine.data.run_id;
              generatedQuestions = parsedLine.data.generated_questions;
              dispatch(updateFinalAnswer(accumulatedAnswer));
              dispatch(updateBotMessageInfo({botMessageId, runId, generatedQuestions, })); // Dispatch botMessageId and runId
              return accumulatedAnswer; // Final response
            }
          } catch (error) {
            console.error('Error parsing line:', line, error);
          }
        }
      }
      return accumulatedAnswer; // Return accumulated answer if no "final" field
    } catch (error) {
      console.error('Error during PDF chat:', error);
      return rejectWithValue(error.message || 'An error occurred while fetching the PDF chat response');
    }
  }
);
const pdfChatSlice = createSlice({
  name: 'pdfChat',
  initialState: {
    messages: [],
    partialAnswer: '',
    finalAnswer: '',
    status: 'idle',
    error: null,
    botMessageId: null, // Store bot_message_id
    runId: null,
    generatedQuestions: null,
    feedback: {}, // Store run_id
  },
  reducers: {
    resetPdfChatState: (state) => {
      state.messages = [];
      state.partialAnswer = '';
      state.finalAnswer = '';
      state.status = 'idle';
      state.error = null;
      state.botMessageId = null; // Reset bot_message_id
      state.runId = null;
      state.generatedQuestions = null;
      state.feedback = {};  // Reset run_id
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId , generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions; 
    },
    updateFeedback: (state, action) => {
      const { messageId, feedbackType } = action.payload;
      state.feedback[messageId] = feedbackType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPdfChat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPdfChat.fulfilled, (state, action) => {
        state.messages.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(fetchPdfChat.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      });
  },
});
export const { resetPdfChatState, updatePartialAnswer, updateFinalAnswer, updateBotMessageInfo } = pdfChatSlice.actions;
export default pdfChatSlice.reducer;
