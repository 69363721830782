import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchChatContract,
  resetChatContractState,
} from "../../Features/chatContractSlice";
import { sendFeedback } from "../../Features/sendFeedback";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Header } from "../../components/Header/Header";
import "./contractChat.css";

const ContractChat = () => {
  const chatContainerRef = useRef(null);
  const [isCopyPopupVisible, setCopyPopupVisible] = useState(false);
  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [thumbsDownClicked, setThumbsDownClicked] = useState({});
  const [typedQuestions, setTypedQuestions] = useState([]);
  const [showDummyMessages, setShowDummyMessages] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [isSending, setIsSending] = useState(false); // Track sending state

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [clientMessage, setClientMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([
    { id: Date.now(), sender: "bot", text: "" },
  ]);
  const [contractUrl, setContractUrl] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const finalAnswer = useSelector((state) => state.pdfChat?.finalAnswer || "");
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});
  const [clickedIndex, setClickedIndex] = useState(null); // Track the clicked box

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [messageIdToFeedback, setMessageIdToFeedback] = useState(null);
  const [runIdToFeedback, setRunIdToFeedback] = useState(null);
  const [showThumbsDownPopup, setShowThumbsDownPopup] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const textareaRef = useRef(null);

  const contractKey = new URLSearchParams(location.search).get("ckey") || "";
  const { botMessageId, runId, generatedQuestions } = useSelector(
    (state) => state.chatContract
  );

  console.log("id", botMessageId);
  const partialAnswer = useSelector(
    (state) => state.chatContract.partialAnswer
  );
  const status = useSelector((state) => state.chatContract.status);
  const error = useSelector((state) => state.chatContract.error);

  useEffect(() => {
    const fetchContractUrl = async () => {
      if (!contractKey) return;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/contract/view-contract/${contractKey}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200 && response.data.contract_url) {
          setContractUrl(response.data.contract_url);
        } else {
          console.error("Contract URL not found.");
        }
      } catch (error) {
        console.error("Error fetching contract URL:", error);
      }
    };

    fetchContractUrl();
  }, [contractKey]);

  useEffect(() => {
    const fetchSummary = async () => {
      if (contractKey) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/contract/summary-and-questions/?contract_key=${contractKey}`,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          const { summary, example_questions } = response.data;

          // Stream the summary
          streamText(
            summary,
            (chunk) => {
              setChatHistory((prev) => [
                { id: Date.now(), sender: "bot", text: chunk }, // Update with streamed chunk
                ...prev.slice(1),
              ]);
            },
            1
          ); // Adjust speed for streaming summary

          // Process and stream example questions
          if (example_questions) {
            const questions = example_questions
              .split(";")
              .map((q) => q.trim())
              .slice(0, 2);
            setTypedQuestions(new Array(questions.length).fill("")); // Initialize typedQuestions

            questions.forEach((question, index) => {
              streamText(
                question,
                (chunk) => {
                  setTypedQuestions((prev) => {
                    const updatedQuestions = [...prev];
                    updatedQuestions[index] = chunk; // Update streamed chunk
                    return updatedQuestions;
                  });
                },
                30
              ); // Adjust speed for streaming questions
            });
          }
        } catch (error) {
          console.error("Error fetching summary and questions:", error);
        }
      }
    };

    fetchSummary();
  }, [contractKey]);

  const typeQuestion = (question, index) => {
    let currentText = "";
    let charIndex = 0;

    const interval = setInterval(() => {
      currentText += question[charIndex];
      setTypedQuestions((prev) => {
        const updatedQuestions = [...prev];
        updatedQuestions[index] = currentText;
        return updatedQuestions;
      });

      charIndex += 1;

      if (charIndex === question.length) {
        clearInterval(interval);
      }
    }, 10); // Adjust the typing speed (100ms per character)
  };

  useEffect(() => {
    if (generatedQuestions) {
      const questions = generatedQuestions
        .split(";")
        .map((q) => q.trim())
        .slice(0, 2);
      setTypedQuestions(new Array(questions.length).fill(""));

      questions.forEach((question, index) => {
        typeQuestion(question, index);
      });
    }
  }, [generatedQuestions]);
  const [placeholder, setPlaceholder] = useState("Ask me anything");
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

  const handleSend = (message = clientMessage) => {
    if (isAwaitingResponse) return;
    if (typeof message !== "string") {
      console.error("Invalid message:", message);
      return;
    }
    if (isSending) return;
    
    if (message.trim() && contractKey) {
      const userMessage = { id: Date.now(), sender: "user", text: message };
  
      setChatHistory((prev) => [...prev, userMessage]);
      setIsAwaitingResponse(true);
      dispatch(fetchChatContract({ contractKey, clientMessage: message }));
  
      // Reset input field, height, and placeholder
      setClientMessage(""); 
      setPlaceholder("Ask me anything");
      setTextAreaHeight("auto");
      setShowDummyMessages(false);
      setClickedIndex(null);
  
      // Reset the textarea height
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; // Reset to initial height
      }
    }
  };
  useEffect(() => {
    if (status === "succeeded" || status === "failed") {
      setIsAwaitingResponse(false); // Allow sending new messages
    }
  }, [status]);
  

  useEffect(() => {
    if (textareaRef.current) {  // Ensure textareaRef is available
      textareaRef.current.style.height = textAreaHeight; // Apply height when it's available
    }

    // Cleanup function to flush the ref after the height is set
    return () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = textAreaHeight; // Reset the height when not in use
      }
    };
  }, [textAreaHeight]);

  const handleInputBackspace = () => {
    if (clientMessage.length > 0) {
      // Simulate a backspace effect
      setClientMessage(clientMessage.slice(0, -1));
    }
  };

  useEffect(() => {
    if (status === "loading" && partialAnswer) {
      setChatHistory((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage?.sender === "bot") {
          lastMessage.text = partialAnswer;
          return [...prev];
        } else {
          return [
            ...prev,
            { id: Date.now(), sender: "bot", text: partialAnswer },
          ];
        }
      });
    }
  }, [partialAnswer, status]);

  const formatText = useCallback((input) => {
    let formattedText = input
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/###(.*?)/g, "<strong>$1</strong>")
      .replace(/^-\s(.+)/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gs, "<ul>$1</ul>")
      .replace(/^([^:\n]+):\s*(.+)$/gm, "<b>$1:</b> $2");

    formattedText = formattedText.replace(/\n/g, "<br/>");

    return `<p style="white-space: pre-wrap; word-wrap: break-word; overflow-wrap: break-word;">${formattedText}</p>`;
  }, []);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    return () => {
      dispatch(resetChatContractState());
    };
  }, [dispatch]);

  const handleThumbsUpClick = (messageId) => {
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle thumbs-up for the specific message
    }));

    // Turn off thumbs-down if it is currently active
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: false, // Reset thumbs-down for this message
    }));

    if (!thumbsUpClicked[messageId]) {
      // Show popup and submit feedback for the specific message
      setPopupVisible(true);
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("up");
      submitFeedback(); // Optional: Trigger the feedback submission
    }
  };

  const handleThumbsDownClick = (messageId) => {
    setThumbsDownClicked((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle thumbs-down for the specific message
    }));

    // Turn off thumbs-up if it is currently active
    setThumbsUpClicked((prev) => ({
      ...prev,
      [messageId]: false, // Reset thumbs-up for this message
    }));

    if (!thumbsDownClicked[messageId]) {
      // Show thumbs-down feedback popup for the specific message
      setMessageIdToFeedback(messageId);
      setRunIdToFeedback(runId);
      setSelectedFeedbackType("down");
      setShowThumbsDownPopup(true);
    }
  };

  const handleCopy = (messageId, text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageId(messageId); // Set the copied message ID
        setTimeout(() => setCopiedMessageId(null), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy message:", err);
      });
  };

  useEffect(() => {
    if (finalAnswer) {
      setThumbsUpClicked({}); // Reset thumbs-up state when bot sends a final answer
    }
  }, [finalAnswer]);

  const submitFeedback = async () => {
    try {
      // Ensure message_id and run_id are set correctly
      if (!botMessageId || !runId || !selectedFeedbackType) {
        console.error("Missing required data for feedback submission.");
        return; // Stop submission if any required fields are missing
      }

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contract/feedback/`,
        {
          message_id: botMessageId, // Ensure botMessageId is set
          run_id: runId, // Ensure runId is set
          feedback_type: selectedFeedbackType, // Ensure feedback_type is "up" or "down"
          user_feedback: feedbackMessage || "", // Optional feedback message
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Check response
      if (response.data.msg === "Feedback recorded successfully.") {
        console.log("Feedback submitted successfully!");
        setShowFeedbackModal(false);
      } else {
        console.error("Feedback submission failed:", response.data.msg);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    factuallyIncorrect: false,
    refused: false,
    notFollowingInstructions: false,
  });

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleSubmitThumbsDownFeedback = async () => {
    // Gather selected checkbox feedback
    const selectedFeedback = [];
    if (selectedCheckboxes.factuallyIncorrect) {
      selectedFeedback.push("Not factually correct.");
    }
    if (selectedCheckboxes.refused) {
      selectedFeedback.push("Refused when it shouldn’t have.");
    }
    if (selectedCheckboxes.notFollowingInstructions) {
      selectedFeedback.push("Not Following Instructions.");
    }

    // Combine selected feedback and placeholder message
    const combinedFeedback = [...selectedFeedback, feedbackMessage].join("\n");

    setFeedbackMessage(combinedFeedback); // Update feedbackMessage before submitting

    try {
      // Submit feedback
      await submitFeedback();

      // After successful submission, reset the state
      setSelectedCheckboxes({
        factuallyIncorrect: false,
        refused: false,
        notFollowingInstructions: false,
      });
      setFeedbackMessage(""); // Clear the feedback message

      // Close the popup
      setShowThumbsDownPopup(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClosePopup = () => {
    setPopupVisible(false); // Close the thumbs-up popup
    setShowThumbsDownPopup(false); // Close the thumbs-down popup

    // Reset feedback state when popup is closed without submission
    setSelectedCheckboxes({
      factuallyIncorrect: false,
      refused: false,
      notFollowingInstructions: false,
    });

    setFeedbackMessage(""); // Reset feedback message
  };

  const inputField = document.querySelector(".custom-input-field-contract");
  const inputContainer = document.querySelector(".custom-input-container");

  const handleInput = () => {
    // Reset the height to auto to allow natural resizing
    inputField.style.height = "auto";

    // Set the height based on the scrollHeight (content height) of the input
    if (inputField.scrollHeight <= 88) {
      inputField.style.height = `${inputField.scrollHeight}px`; // Grow based on content
      inputContainer.style.height = `${inputField.scrollHeight + 20}px`; // Adjust container height
    } else {
      inputField.style.height = "88px"; // Max height set to 88px
      inputContainer.style.height = "88px"; // Prevent container from growing beyond 88px
    }
  };

  // Apply the handleInput function after typing
  if (inputField) {
    inputField.addEventListener("input", handleInput);
  }

  useEffect(() => {
    // Reset thumbs-down state when a new message arrives
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
      // setThumbsDownClicked({});  // Clear thumbs-down state for all messages
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory[chatHistory.length - 1]?.sender === "bot") {
      // setThumbsUpClicked({});  // Clear thumbs-up state for all messages
      // setThumbsDownClicked({});  // Clear thumbs-down state for all messages
    }
  }, [chatHistory]);

  useEffect(() => {
    console.log("Status updated:", status);
    if (status === "loading") {
      // Hide dummy messages when bot is typing
      setShowDummyMessages(false);
    } else if (status === "succeeded" || status === "failed") {
      // Show dummy messages again after bot's response
      setShowDummyMessages(true);
    }
  }, [status]); // Triggered when the bot response is received
  const streamText = (text, callback, delay = 30) => {
    let charIndex = 0;
    const interval = setInterval(() => {
      const chunk = text.slice(0, charIndex + 1); // Add one character at a time
      callback(chunk);
  
      charIndex += 1;
      if (charIndex >= text.length) {
        clearInterval(interval); // Stop when all characters are streamed
      }
    }, delay);
  };
  

  return (
    <div className="full-container-chat">
      <div className="">
        <Header icon="/arrow-back.svg" name="Go to Contracts" />
      </div>
      <div className="content-container">
        {/* Chat Section */}
        <div className="left-side">
          <div className="message-container" ref={chatContainerRef}>
            {chatHistory.map((message, index) => (
              <div
                key={index}
                className={`mb-2 ${
                  message.sender === "user"
                    ? "w-full flex justify-end"
                    : "bot-message-container w-full flex items-start"
                }`}
              >
                {/* Bot Icon */}
                {message.sender === "bot" && (
                  <div className="bot-icon-container flex-shrink-0">
                    <img
                      src="/bot-icon.svg"
                      alt="Bot Icon"
                      className="bot-icon h-6 w-6 rounded-full mr-2"
                    />
                  </div>
                )}

                {/* Bot Message with Flexbox */}
                {message.sender === "bot" ? (
                  <div className="flex flex-col">
                    {/* Message Bubble */}
                    <span
                      className="bot-message-content"
                      dangerouslySetInnerHTML={{
                        __html: formatText(message.text),
                      }}
                    />

                    {/* Reaction Icons */}
                    <div className="reactions flex gap-2 text-gray-500 mt-2">
                      {/* <span className="reaction-icon cursor-pointer">
    <img src="/charge.svg" alt="Charge" className="charge-icon" />
    <span className="tooltip">Coming Soon</span>
  </span> */}

                      <span
                        className="reaction-icon cursor-pointer"
                        onClick={() => handleThumbsUpClick(message.id)}
                      >
                        <img
                          src={
                            thumbsUpClicked[message.id]
                              ? "/thums-up-filled.svg" // Filled icon when thumbs-up is clicked
                              : "/thums-up.svg" // Default icon
                          }
                          alt="Thumbs Up"
                        />
                      </span>

                      <span
                        className="reaction-icon cursor-pointer"
                        onClick={() => handleThumbsDownClick(message.id)}
                      >
                        <img
                          src={
                            thumbsDownClicked[message.id]
                              ? "/thums-down-filled.svg" // Filled icon when thumbs-down is clicked
                              : "/thums-down.svg" // Default icon
                          }
                          alt="Thumbs Down"
                        />
                      </span>

                      <span
                        className="reaction-icon cursor-pointer"
                        onClick={() => handleCopy(message.id, message.text)}
                      >
                        <img
                          src={
                            copiedMessageId === message.id
                              ? "/copy-clicked.svg" // Use this SVG when the message is copied
                              : "/copy.svg" // Default SVG
                          }
                          alt="Copy"
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="user-message-bubble">{message.text}</span>
                )}
              </div>
            ))}

            {status === "failed" && (
              <div className="bot-message-container w-full flex items-start mb-4">
                <div className="bot-message-content text-red-600 text-sm p-4 max-w-[85%]">
                  <strong>Error:</strong> {error}
                </div>
              </div>
            )}
          </div>

          {/* Input Section */}
          <div className="dummy-container flex flex-col gap-2 md:gap-2 mr-20 ">
            {showDummyMessages &&
              typedQuestions.map((question, index) => (
                <div
                  key={index}
                  className={`dummy-message-box border border-purple-400 rounded-lg p-4 flex items-center cursor-pointer ${
                    clickedIndex === index ? "opacity-50" : "opacity-100"
                  }`}
                  onClick={() => {
                    setClickedIndex(index); // Set the clicked index
                    setClientMessage(question.trim()); // Set the question to clientMessage
                  }}
                >
                  <span className="icon mr-2">
                    <img src="/star-dot.svg" alt="Star Icon" />
                  </span>
                  <p className="text-sm">{question.trim()}</p>
                </div>
              ))}
          </div>

          <div className="custom-input-wrapper">
            <div className="input-container-holder">
              <textarea
                placeholder={placeholder}
                className="input-field-holder w-4/5 min-h-[22px] resize-none text-[#3f3f46] font-outfit text-lg font-semibold leading-6 ml-[35px] mt-[17px] border-0 outline-none bg-transparent overflow-hidden max-h-[88px] overflow-y-auto"
                value={clientMessage} // Shows the selected dummy question or user's input
                onChange={(e) => {
                  setClientMessage(e.target.value);
                  if (textareaRef.current) {
                    textareaRef.current.style.height = "auto"; // Reset height
                    textareaRef.current.style.height = `${Math.min(e.target.scrollHeight, 88)}px`; // Set dynamic height
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line
                    if (clientMessage.trim()) {
                      handleSend(clientMessage.trim()); // Send the message if it's not empty
                      setClientMessage(""); // Clear the input field
                    }
                  }
                }}
                ref={textareaRef} // Ensure ref is attached
                style={{
                  height: textAreaHeight,
                  boxSizing: "border-box",
                }}
              ></textarea>
              <button
                className="send-button-holder"
                onClick={() => {
                  setTextAreaHeight("15px")
                  handleSend();
                }}
                disabled={isAwaitingResponse} 
              >
                {isAwaitingResponse ? (
    <img src="/loading-send-button.svg" alt="Loading" /> // Loading SVG during the waiting period
  ) : (
    <img src="/send.svg" alt="Send" /> // Default send SVG
  )}
              </button>
            </div>
          </div>
        </div>

        {/* PDF Viewer Section */}
        <div className="right-side">
          <iframe
            src={contractUrl} // Dynamically set the PDF URL
            title="PDF Viewer"
            className="w-full h-full"
            allow="fullscreen"
          />
        </div>
      </div>

      {showThumbsDownPopup && (
        <div className="popup-container-thumsdown">
          <div className="popup-content-thumsdown">
            {/* Top Section: Icon and Close Button */}
            <div>
              <div>
                <button
                  className="close-button-thumsdown"
                  onClick={() => setShowThumbsDownPopup(false)}
                >
                  <p>x</p>
                </button>
              </div>
              <div className="paragraph-container">
                <p className="paragraph-container-para1">What went Wrong?</p>
                <p className="paragraph-container-para2">
                  Select feedback that reflects how well this response addressed
                  your question or concern.
                </p>
                <div class="checkbox-container-thumsup">
                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup1"
                      id="factuallyIncorrect"
                      checked={selectedCheckboxes.factuallyIncorrect}
                      onChange={handleCheckboxChange}
                    />
                    Not factually correct.
                  </label>

                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup2"
                      id="refused"
                      checked={selectedCheckboxes.refused}
                      onChange={handleCheckboxChange}
                    />
                    Refused when it shouldn’t have.
                  </label>

                  <label className="thumsup-checkbox">
                    <input
                      type="checkbox"
                      className="thumbsup3"
                      id="notFollowingInstructions"
                      checked={selectedCheckboxes.notFollowingInstructions}
                      onChange={handleCheckboxChange}
                    />
                    Not Following Instructions.
                  </label>
                </div>
                <div class="line-with-text">
                  <span>Or</span>
                </div>
                <div class="rectangular-box">
                  <div class="feedback-container">
                    <textarea
                      className="feedback-input resize-none border-none focus:outline-none focus:ring-0"
                      value={feedbackMessage} // Bind the textarea value to feedbackMessage state
                      maxlength="250"
                      placeholder="Your feedback is much Appreciated and helps improve the AI!"
                      onChange={(e) => setFeedbackMessage(e.target.value)} // Update feedbackMessage state on input change
                    ></textarea>
                  </div>
                  <p class="character-count">Maximum: 250 characters</p>
                </div>

                <button
                  className="custom-button-thumsdown"
                  onClick={handleSubmitThumbsDownFeedback}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractChat;
